<template>
  <transition name="modal-fade">
    <div>
      <div
        class="flex justify-between items-baseline"
        :class="workflow[step].isModal !== true ? 'mr-6' : ''"
      >
        <div class="text-muted_black text-xl font-bold">
          <span v-if="workflow[step].isModal === true">{{ $t('settings.taxCategories.reorg.fusion_workflow.modal_title') }}</span>
          <span v-else>{{ $t('settings.taxCategories.reorg.fusion_selector.headline') }}</span>
        </div>

        <div class="text-gray_dark text-right text-base">
          {{ $t(
            'settings.taxCategories.reorg.fusion_workflow.step_info',
            {
              step_number: step + 1,
              steps_total: workflow.length
            }
          ) }}
        </div>
      </div>

      <hr
        class="hr-line-user my-6"
        :class="workflow[step].isModal !== true ? 'mr-6' : ''"
      />

      <div :class="workflow[step].isModal !== true ? 'min-h-80 mr-6' : 'min-h-48'">
        <div class="step-title">
          <span v-if="(workflow[step].isCheckedNegative !== true) && (workflow[step].id !== 'manage_documents')">
            {{ workflow[step].title }}
          </span>
          <span v-if="workflow[step].isCheckedNegative === true">
            {{ workflow[step].titleCheckedNegative }}
          </span>
        </div>

        <i
          v-show="workflow[step].isProcessing === true"
          class="pi pi-spin pi-spinner text-3xl text-green"
        />

        <div
          v-show="workflow[step].hasUnknownError === true"
          class="workflow-message error-message"
        >
          <div>{{ $t('settings.taxCategories.reorg.could_not_check') }}</div>
          <div>{{ $t('general.errors.unknownError') }}</div>
        </div>

        <div
          class="workflow-message"
          v-show="workflow[step].isCheckedNegative === true"
          v-html="workflow[step].messageCheckedNegative"
        />
        <div
          class="workflow-message"
          v-show="workflow[step].isCheckedPositive === true"
          v-html="workflow[step].messageCheckedPositive"
        />
        <div
          class="workflow-message"
          v-show="typeof workflow[step].messageDefault === 'string'"
          v-html="workflow[step].messageDefault"
        />

        <div
          class="workflow-message text-left"
          v-show="workflow[step].id === 'show_cost'"
        >
          {{ $t('settings.taxCategories.reorg.fusion_workflow.show_cost.template_1').replace('%price%', price) }}
          <div class="font-base text-base text-green my-6">
            <div class="mb-1.5">{{ $t('settings.taxCategories.reorg.fusion_workflow.show_cost.sub_title') }}</div>
            <div>
              <span class="font-bold text-muted_black">{{ price }}&nbsp;€&nbsp;</span>
              <span class="text-muted_black">{{ $t('settings.taxCategories.reorg.fusion_workflow.show_cost.plus_vat', {vatPercentValue: vatPercentValue}) }}</span>
            </div>
          </div>
          <div class="text-info-box">
            <img class="image" src="@/assets/info_gray.svg"/>
            <label class="text-left ml-3">
              <div class="font-bold mb-2">{{ $t('settings.taxCategories.reorg.fusion_workflow.show_cost.info_box.title') }}</div>
              {{ $t('settings.taxCategories.reorg.fusion_workflow.show_cost.info_box.content') }}
            </label>
          </div>
        </div>

        <div
          class="workflow-message text-left"
          v-show="workflow[step].id === 'select_account'"
        >
          <p>{{ $t('settings.taxCategories.reorg.fusion_workflow.select_account.template_1') }}</p>

          <div class="flex justify-around space-x-10 mt-3 mb-7">
            <InputText
              class="mt-2 w-full input-gstb-number"
              :maxLength="8"
              :label="$t('settings.taxCategories.reorg.fusion_workflow.select_account.target_number_input_label')"
              :isRequired="true"
              v-model="targetGstbNumber"
              prefix="GSTB-"
              :isOnlyNumber="true"
            />
          </div>

          <div class="text-info-box error">
            <img class="image" src="@/assets/info.svg"/>
            <label class="text-left ml-3">
              <div class="font-bold mb-2">{{ $t('settings.taxCategories.reorg.fusion_workflow.select_account.info_box.title') }}</div>
              {{ $t('settings.taxCategories.reorg.fusion_workflow.select_account.info_box.content') }}
            </label>
          </div>
        </div>

        <div
          class="workflow-message text-left"
          v-show="workflow[step].id === 'approve_fusion'"
        >
          <div class="mb-3">
            {{ $t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.template_1') }}
          </div>

          <div class="flex justify-start items-baseline text-left mb-3">
            <i class="pi pi-arrow-right pi-font-bullet"/>
            <label class="mb-2">
              {{ $t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.template_2') }}
            </label>
          </div>

          <div class="flex justify-start items-baseline text-left mb-3">
            <i class="pi pi-arrow-right pi-font-bullet"/>
            <label class="mb-2">
              {{ $t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.template_3') }}
            </label>
          </div>

          <div class="mb-3">
            {{ $t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.template_4') }}
          </div>

          <div class="flex flex-col space-y-6 mt-4 mb-7">
            <InputCheck
              class="inputcheck-conditions"
              :isChecked="conditionsAccepted"
              :text="$t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.template_5')"
              :isRequired="true"
              @check="conditionsAccepted = !conditionsAccepted"
            />
          </div>

          <div class="text-info-box error">
            <img class="image" src="@/assets/info.svg"/>
            <label class="text-left ml-3">
              <div class="font-bold mb-2">{{ $t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.info_box.title') }}</div>
              {{ $t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.info_box.content') }}
            </label>
          </div>
        </div>

        <div
          class="workflow-message modal"
          v-show="workflow[step].id === 'manage_documents' && uploadedDocumentsChecked && (needsUpload || mustStayAtStepManageDocuments)"
        >
          <div class="text-info-box">
            <img class="image" src="@/assets/info_gray.svg"/>
            <label class="text-base text-left ml-3">
              {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.template_1') }}
            </label>
          </div>

          <div class="flex flex-row gap-2.5">
            <div class="bg-gray_light flex-auto flex flex-col p-3 rounded">
              <div class="step-title">
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.title') }}
              </div>
              <div class="workflow-message no-border text-left h-32">
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.template_1') }}
              </div>
              <div class="workflow-message no-border text-left font-bold">
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.sub_title') }}
              </div>
              <div class="doc-list-container">
                <div
                  class="doc-list-item"
                  :class="hasDocument('generated_legitimation_file_key') ? '' : 'download-disabled'"
                  @click="downloadFusionDocument('generated_legitimation_file_key')"
                >
                  <img
                    v-show="!isFetchingFusion"
                    class="document-icon ml-2"
                    src="@/assets/document_pdf.svg"
                  />
                  <div
                    v-show="!isFetchingFusion"
                    class="document-name"
                  >
                    {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.generated_legitimation_file_key_label') }}
                  </div>
                  <i
                    v-show="!isFetchingFusion"
                    class="pi pi-arrow-circle-down download-icon"
                  />
                </div>

                <div
                  class="doc-list-item"
                  :class="hasDocument('clients_overview_file_key') ? '' : 'download-disabled'"
                  @click="downloadFusionDocument('clients_overview_file_key')"
                >
                  <img
                    v-show="!isFetchingFusion"
                    class="document-icon ml-2"
                    src="@/assets/document_xls.svg"
                  />
                  <div
                    v-show="!isFetchingFusion"
                    class="document-name"
                  >
                    {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.clients_overview_file_key_label') }}
                  </div>
                  <i
                    v-show="!isFetchingFusion"
                    class="pi pi-arrow-circle-down download-icon"
                  />
                </div>
              </div>
            </div>

            <div class="bg-gray_light flex-auto flex flex-col p-3 rounded">
              <div class="step-title">
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.title') }}
              </div>
              <div class="workflow-message no-border text-left h-32">
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.template_1') }}
              </div>

              <div
                class="workflow-message no-border text-left font-bold"
                v-show="!isFetchingFusion && (hasDocument('identity_documents_file_key') || hasDocument('identity_documents_file_key'))"
              >
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.sub_title_uploaded') }}
              </div>
              <div
                class="doc-list-container"
                v-show="!isFetchingFusion && (hasDocument('identity_documents_file_key') || hasDocument('identity_documents_file_key'))"
              >
                <div
                  v-show="hasDocument('uploaded_legitimation_file_key')"
                  class="doc-list-item"
                  @click="downloadFusionDocument('uploaded_legitimation_file_key')"
                >
                  <img v-show="!isFetchingFusion" class="document-icon ml-2" src="@/assets/document_pdf.svg"/>
                  <div v-show="!isFetchingFusion" class="document-name">
                    {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.uploaded_legitimation_file_key_label') }}
                  </div>
                  <i v-show="!isFetchingFusion" class="pi pi-arrow-circle-down download-icon"/>
                </div>
                <div
                  v-show="hasDocument('identity_documents_file_key')"
                  class="doc-list-item"
                  @click="downloadFusionDocument('identity_documents_file_key')"
                >
                  <img v-show="!isFetchingFusion" class="document-icon ml-2" src="@/assets/document_pdf.svg"/>
                  <div v-show="!isFetchingFusion" class="document-name">
                    {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.download.identity_documents_file_key_label') }}
                  </div>
                  <i v-show="!isFetchingFusion" class="pi pi-arrow-circle-down download-icon"/>
                </div>
              </div>

              <div class="workflow-message no-border text-left font-bold">
                {{ $t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.sub_title_new_upload') }}
              </div>
              <div class="doc-list-container rounded">
                <InputUpload
                  ref="uploadLegitimation"
                  :label="$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.label_legitimation')"
                  :multiple="false"
                  class="upload-item"
                  accept=".pdf"
                  :isBlackText="true"
                  :showDragAndDrop="false"
                  :isRequired="true"
                />
                <InputUpload
                  ref="uploadId"
                  :label="$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.label_id')"
                  :multiple="false"
                  class="upload-item"
                  accept=".pdf"
                  :isBlackText="true"
                  :showDragAndDrop="false"
                  :isRequired="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="workflow-message text-left"
          v-show="workflow[step].id === 'read_receipt'"
        >
          <div class="mb-3">
            {{ $t('settings.taxCategories.reorg.fusion_workflow.read_receipt.template_1') }}
          </div>
          <div class="mb-3">
            {{ $t('settings.taxCategories.reorg.fusion_workflow.read_receipt.template_2') }}
          </div>
        </div>
      </div>

      <div
        class="tab-footer"
        :class="workflow[step].isModal !== true ? 'mr-6' : ''"
      >
        <hr class="hr-line-user mt-6">
        <div class="flex justify-end mt-5 space-x-6">
          <Button
            v-show="workflow[step].showCancelButton === true"
            @click="cancelWorkflow"
            class="button-label text-center"
            secondary
            :text="workflow[step].cancelButtonText ? workflow[step].cancelButtonText : $t('buttons.cancel')"
          />
          <Button
            v-show="mustShowWorkflowNegativeButton()"
            class="button-label text-center"
            primary
            :text="workflow[step].buttonCheckedNegative"
            @click="handleFunctionCall(workflow[step].buttonCheckedNegativeAction)"
          />
          <Button
            v-show="(workflow[step].showNextButton === true) && (isNextButtonDisabled() === false)"
            class="button-label text-center bg-green text-white disabled"
            primary
            :disabled="false"
            :text="workflow[step].nextButtonText ? workflow[step].nextButtonText : $t('buttons.next')"
            @click="handleNextButton"
          />
          <Button
            v-show="(workflow[step].showNextButton === true) && (isNextButtonDisabled() === true)"
            class="button-label text-center text-white disabled"
            secondary
            :disabled="true"
            :text="workflow[step].nextButtonText ? workflow[step].nextButtonText : $t('buttons.next')"
          />
          <Button
            v-show="workflow[step].showLogoutButton === true && workflow[step].id !== 'manage_documents'"
            class="button-label text-center bg-green text-white disabled"
            primary
            :disabled="false"
            :text="$t('sidebar.popup.logout')"
            @click="logout"
          />
          <Button
            v-show="workflow[step].showLogoutButton === true && workflow[step].id === 'manage_documents' && uploadedDocumentsChecked && (needsUpload || mustStayAtStepManageDocuments)"
            class="button-label text-center bg-green text-white disabled"
            secondary
            :disabled="false"
            :text="$t('sidebar.popup.logout')"
            @click="logout"
          />
          <Button
            v-show="workflow[step].id === 'manage_documents' && canUploadFusionDocuments() === true && uploadedDocumentsChecked && (needsUpload || mustStayAtStepManageDocuments)"
            class="button-label text-center bg-green text-white disabled"
            primary
            :disabled="false"
            :text="$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.button_send')"
            @click="uploadFusionDocuments"
          />
          <Button
            v-show="workflow[step].id === 'manage_documents' && canUploadFusionDocuments() === false && uploadedDocumentsChecked && (needsUpload || mustStayAtStepManageDocuments)"
            class="button-label text-center bg-green text-white disabled"
            secondary
            :disabled="true"
            :text="$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.button_send')"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {ApiService} from '@/services/api.service';
import {BillingService} from '@/services/billing.service.js';
import {AccountFusionService} from '@/services/accountFusion.service';
import InputText from '@/components/inputs/InputText.vue';
import InputCheck from '@/components/inputs/InputCheck.vue';
import InputUpload from '@/components/inputs/InputUpload.vue';
import { mapGetters, mapActions } from 'vuex';
import { appMixin } from '@/core/utils/mixin';

export default {
  name: 'AccountFusion',
  emits: ['cancel_workflow'],
  components: {
    InputText,
    InputCheck,
    InputUpload
  },
  mixins: [appMixin],
  data() {
    return {
      showChangeSubscriptionModal: false,
      changeSubscriptionAfterEndTerm: false,

      fusionService: undefined,
      isFetchingFusion: false,
      isUploading: false,

      uploadedDocumentsChecked: false,
      needsUpload: false,
      mustStayAtStepManageDocuments: false,

      step: 0,
      countUnpaidInvoices: null,
      checkPaymentsForwardTimeoutId: undefined,
      targetGstbNumber: '',
      conditionsAccepted: false,
      price: 600,
      vatPercentValue: 19,
      workflow: [
        {
          id: 'check_payments',
          title: this.$t('settings.taxCategories.reorg.fusion_workflow.check_payments.title'),
          titleCheckedNegative: '',
          messageCheckedNegative: '',
          buttonCheckedNegative: '',
          buttonCheckedNegativeAction: 'checkPaymentsNegativeButtonClick',
          messageCheckedPositive: this.$t('settings.taxCategories.reorg.fusion_workflow.check_payments.checked_positive.message'),
          isProcessing: false,
          isCheckedPositive: false,
          isCheckedNegative: false,
          hasUnknownError: false,
          showCancelButton: true,
        },
        {
          id: 'show_cost',
          title: this.$t('settings.taxCategories.reorg.fusion_workflow.show_cost.title'),
          messageDefault: undefined,
          isProcessing: false,
          isCheckedPositive: false,
          isCheckedNegative: false,
          hasUnknownError: false,
          showCancelButton: true,
        },
        {
          id: 'select_account',
          title: this.$t('settings.taxCategories.reorg.fusion_workflow.select_account.title'),
          showCancelButton: true,
        },
        {
          id: 'approve_fusion',
          title: this.$t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.title'),
          nextButtonText: this.$t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.next_button_text'),
          showCancelButton: true,
        },
        {
          id: 'manage_documents',
          title: '',
          showCancelButton: true,
          cancelButtonText: this.$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.cancel_button_text'),
          showLogoutButton: true,
          isModal: true,
        },
        {
          id: 'read_receipt',
          title: this.$t('settings.taxCategories.reorg.fusion_workflow.read_receipt.title'),
          showCancelButton: false,
          showLogoutButton: true,
          isModal: true,
        }
      ],
      fileKeyAttrsAllowed: [
        'generated_legitimation_file_key',
        'clients_overview_file_key',
        'uploaded_legitimation_file_key',
        'identity_documents_file_key',
      ]
    }
  },
  beforeMount() {
    this.fusionService = new AccountFusionService();
    this.$store.commit('account_reorganisation/initSourceRole');
  },
  mounted() {
  },
  watch: {
    isLoading(newVal) {
      if (newVal === true) {
        this.$loading.show();
      } else {
        this.$loading.hide();
      }
    },
    '$store.state.account_reorganisation.openSourceRoleFusionData': {
      handler: function (val) {
        if (this.step !== this.getWorkflowStepByWorkflowId('manage_documents')) {
          return;
        }

        if (val.prim_uid > 0) {
          if (
            typeof val.uploaded_legitimation_file_key === 'string'
            && val.uploaded_legitimation_file_key.trim() !== ''
            && typeof val.identity_documents_file_key === 'string'
            && val.identity_documents_file_key.trim() !== ''
          ) {
            this.needsUpload = false;
            this.uploadedDocumentsChecked = true;
            if (!this.mustStayAtStepManageDocuments) {
              this.activateStep('read_receipt');
            }
          } else {
            this.needsUpload = true;
            this.uploadedDocumentsChecked = true;
          }
        }
      },
      deep: true
    },
  },
  computed: {
    isLoading() {
      return (this.isFetchingFusion || this.isUploading);
    }
  },
  methods: {
    ...mapActions('global', ['fetchFusionRole']),
    ...mapGetters('account_reorganisation', ['getOpenSourceRoleFusionData', 'getOpenSourceRoleFusionDocuments']),
    canUploadFusionDocuments() {
      if (
        typeof this.$refs === 'undefined'
        || typeof this.$refs['uploadLegitimation'] === 'undefined'
        || typeof this.$refs['uploadId'] === 'undefined'
      ) {
        return false;
      }

      if (
        this.$refs.uploadLegitimation.files.length === 1
        && this.$refs.uploadId.files.length === 1
      ) {
        return true;
      }

      return false;
    },
    hasDocument(fileKeyAttr) {
      let fusion = this.getOpenSourceRoleFusionData();
      if (
        (typeof fileKeyAttr !== 'string')
        || !this.fileKeyAttrsAllowed.includes(fileKeyAttr)
        || !(typeof fusion[fileKeyAttr] === 'string')
      ) {
        return false;
      }

      return fusion[fileKeyAttr].trim() !== '';
    },
    isNextButtonDisabled() {
      let isDisabled = false;

      if (this.getWorkflowIdByWorkflowStep(this.step) === 'select_account') {
        isDisabled = !(
          (typeof this.targetGstbNumber === 'string')
          && (this.targetGstbNumber.length === 8)
          && (/^\d+$/.test(this.targetGstbNumber))
          && this.targetGstbNumber.substring(0, 1) !== '0'
        );
      } else if (this.getWorkflowIdByWorkflowStep(this.step) === 'approve_fusion') {
        isDisabled = this.conditionsAccepted === false;
      }

      return isDisabled;
    },
    mustShowWorkflowNegativeButton() {
      if (
        this.workflow[this.step].isCheckedNegative === true
        && typeof this.workflow[this.step].buttonCheckedNegative === 'string'
        && this.workflow[this.step].buttonCheckedNegative !== ''
      ) {
        return true;
      }

      return false;
    },
    getWorkflowIdByWorkflowStep(workflowStep) {
      if (
        (workflowStep < 0)
        || (workflowStep + 1 > this.workflow.length)
        || (typeof this.workflow[workflowStep].id !== 'string')
      ) {
        return ''
      }

      return this.workflow[workflowStep].id;
    },
    getWorkflowStepByWorkflowId(workflowId) {
      let workflowItem = this.workflow.find(
        item => item.id === workflowId,
        this
      );

      if (!workflowItem) {
        return -1;
      }

      return this.workflow.indexOf(workflowItem);
    },
    setStep(step) {
      if (
        typeof step === 'number'
        && step >= 0
        && step < this.workflow.length
      ) {
        this.step = step;
      }
    },
    updateCheckPaymentsStrings() {
      let stepNumber = this.getWorkflowStepByWorkflowId('check_payments');
      if (
        (stepNumber === -1)
        || (typeof this.countUnpaidInvoices !== 'number')
      ) {
        return;
      }

      let suffix = (this.countUnpaidInvoices === 1) ? '_single' : '_multi';
      this.workflow[stepNumber].titleCheckedNegative = this.$t('settings.taxCategories.reorg.fusion_workflow.check_payments.checked_negative.title' + suffix);
      this.workflow[stepNumber].messageCheckedNegative = this.$t('settings.taxCategories.reorg.fusion_workflow.check_payments.checked_negative.message' + suffix);
      this.workflow[stepNumber].buttonCheckedNegative = this.$t('settings.taxCategories.reorg.fusion_workflow.check_payments.checked_negative.button' + suffix);
    },
    checkPaymentsNegativeButtonClick() {
      this.$router.push('/setting/billing');
    },
    handleFunctionCall(functionName, event) {
      this[functionName](event);
    },
    resetWorkflowStateProperties() {
      for (let i = 0; i < this.workflow.length; i++) {
        this.workflow[i].isProcessing = false;
        this.workflow[i].isCheckedPositive = false;
        this.workflow[i].isCheckedNegative = false;
        this.workflow[i].hasUnknownError = false;
      }
    },
    process(startWithStepId = 'check_payments') {
      let startWithStep = this.getWorkflowStepByWorkflowId(startWithStepId);
      this.resetWorkflowStateProperties();
      this.setStep(startWithStep);

      this.handleFunctionCall('process_step_' + startWithStepId);
    },
    handleNextButton: async function () {
      clearTimeout(this.checkPaymentsForwardTimeoutId);
      if (this.getWorkflowIdByWorkflowStep(this.step) === 'approve_fusion') {
        let fusionData = {
          'targetAccountNumber': this.targetGstbNumber,
        };
        let response = await this.fusionService.startAccountFusion(fusionData, true);
        if (response !== false) {
          if (
            typeof response.data !== 'undefined'
            && typeof response.data.success !== 'undefined'
            && response.data.success === true
          ) {
            this.$toast.success(this.$t('settings.taxCategories.reorg.fusion_workflow.approve_fusion.start_success'));
            await this.updateCurrentUser(true);
            await this.clearAccountSessions();
            await this.setSessionExpired();
          }
        }
      } else if (this.step < this.workflow.length - 1) {
        this.setStep(this.step + 1);
        this.handleFunctionCall('process_step_' + this.workflow[this.step].id);
      }
    },
    updateCurrentUser: async function (show_loader = true) {
      const api = new ApiService();
      await api.get_user(show_loader);
    },
    clearAccountSessions: async function () {
      await this.fusionService.clearAccountSessions({}, true);
    },
    setSessionExpired: async function () {
      await this.fusionService.setSessionExpired();
    },
    process_step_check_payments() {
      this.workflow[this.step].isProcessing = true;
      this.workflow[this.step].showNextButton = false;
      const billingService = new BillingService();
      billingService.getCountUnpaidInvoices().then((response) => {
        this.workflow[this.step].isProcessing = false;
        if (response && response.data && typeof response.data.count === 'number') {
          this.countUnpaidInvoices = response.data.count;
          if (this.countUnpaidInvoices === 0) {
            this.workflow[this.step].isCheckedPositive = true;
            this.workflow[this.step].showNextButton = true;
            if (this.step < this.workflow.length - 1) {
              this.checkPaymentsForwardTimeoutId = setTimeout(
                () => {
                  this.setStep(this.step + 1)
                  this.handleFunctionCall('process_step_' + this.workflow[this.step].id);
                },
                10000
              );
            }
          } else {
            this.updateCheckPaymentsStrings();
            this.workflow[this.step].isCheckedNegative = true;
          }
        } else {
          this.workflow[this.step].hasUnknownError = true;
          this.$toast.error(this.$t('general.errors.unknownError'));
        }
      });
    },
    process_step_show_cost() {
      this.workflow[this.step].showNextButton = true;
    },
    process_step_select_account() {
      this.workflow[this.step].showNextButton = true;
    },
    process_step_approve_fusion() {
      this.workflow[this.step].showNextButton = true;
    },
    fetchOpenSourceRoleFusionData: async function () {
      this.isFetchingFusion = true;
      await this.fusionService.fetchOpenSourceRoleFusionData();
      this.isFetchingFusion = false;
    },
    serviceGetOpenSourceRoleDocuments: async function (data) {
      await this.fusionService.documents(data);
    },
    process_step_manage_documents: async function () {
      this.workflow[this.step].showNextButton = false;
      await this.fetchOpenSourceRoleFusionData();
    },
    process_step_read_receipt() {
      this.workflow[this.step].showNextButton = false;
    },
    cancelWorkflow: async function() {
      if (this.getWorkflowIdByWorkflowStep(this.step) === 'manage_documents') {
        let response = await this.fusionService.abortAccountFusion(true);
        if (response !== false) {
          if (
            typeof response.data !== 'undefined'
            && typeof response.data.success !== 'undefined'
            && response.data.success === true
          ) {
            this.$toast.success(this.$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.abort_success'));
          }
        }
        await this.fetchFusionRole();
      } else {
        this.setStep(0);
      }

      this.countUnpaidInvoices = null;
      this.checkPaymentsForwardTimeoutId = undefined;
      this.targetGstbNumber = '';
      this.conditionsAccepted = false;

      this.$emit('cancel_workflow');
    },
    activateStep(workflowId) {
      let step = this.getWorkflowStepByWorkflowId(workflowId);
      if (step) {
        this.setStep(step);
        this.handleFunctionCall('process_step_' + workflowId);
      }
    },
    downloadFusionDocument(fileKeyAttr) {
      if (!this.hasDocument(fileKeyAttr)) {
        return;
      }

      this.fusionService.downloadDocument({
        uid: this.getOpenSourceRoleFusionData().prim_uid,
        fileKeyAttr: fileKeyAttr
      });
    },
    uploadFusionDocuments: async function() {
      if (!this.canUploadFusionDocuments()) {
        return;
      }

      let formData = new FormData();
      formData.append('uploaded_legitimation_file_key', this.$refs.uploadLegitimation.files[0]);
      formData.append('identity_documents_file_key', this.$refs.uploadId.files[0]);
      formData.append('uid', this.getOpenSourceRoleFusionData().prim_uid);

      this.isUploading = true;
      this.fusionService.trigger('fusion/uploadDocuments', formData, true, 'multipart/form-data').then((response) => {
        if (response.data.success) {
          this.fetchOpenSourceRoleFusionData();
          this.isUploading = false;
          this.$toast.success(this.$t('settings.taxCategories.reorg.fusion_workflow.manage_documents.upload.success'));
        } else {
          this.isUploading = false;
          this.$toast.error(response.data.message);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.step-title {
  @apply font-semibold text-center text-lg text-green my-7;
}

.pi-font-bullet {
  @apply mr-4 font-bold text-base text-green;
}

.workflow-message {
  @apply font-normal rounded text-center text-base text-muted_black mx-auto p-5 max-w-2xl;

  border: 2px solid theme('colors.gray_dark');

  &.no-border {
    border: none;
    padding-top: 0;
  }
}

.workflow-message.modal {
  @apply max-w-full border-0 p-0;
}

.error-message {
  border-color: theme('colors.red');
}

.image {
  width: 18px;
  height: 18px;
}

.text-info-box {
  @apply flex justify-start items-center text-sm p-3 my-4 bg-gray_light rounded;

  &.text-info-box.error {
    @apply bg-red text-white;
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

.doc-list-container {
  @apply flex flex-col items-start text-base font-normal p-5;

  .doc-list-item {
    @apply flex flex-row justify-start items-center text-left gap-2.5 cursor-pointer bg-white p-2.5 min-h-12;

    min-width: max(100%, 360px);

    &:hover {
      @apply text-white bg-green;

      .download-icon {
        @apply text-white;
      }
    }

    &.download-disabled {
      @apply pointer-events-none cursor-auto opacity-70 italic;
    }

    .document-name {
      @apply grow;
    }

    .document-icon {
      @apply flex-none size-8;
    }

    .download-icon {
      @apply flex-none text-3xl text-green;
    }
  }

  .upload-item {
    @apply block text-left p-2.5 bg-white;

    min-width: max(100%, 360px);
  }
}

.fusion-container {
  overflow-x: hidden;
  overflow-y: hidden;
}

:deep(.inputcheck-conditions .checkmark) {
  top: 6px !important;
}
</style>
