<template>
  <div>
    <div v-if="exceedsLimit" class="flex justify-start items-center bg-gray_light rounded-sm p-4 mb-10 w-full">
      <img
        class="image"
        src="@/assets/info_gray.svg"
      />

      <div class="text ml-2">
        {{ $t('properties.step10.animal_info') }}
        <ul class="list-disc pl-5">
          <li
            v-for="animal in exceedingAnimals"
            :key="animal.name"
          >
            {{ animal.title }} {{ animal.count }}
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import {animalsData, hectareLimits} from '@/core/constants';

export default {
  name: 'AnimalUnitsCalculator',
  components: {},
  props: {
    animals: {
      type: Object,
      required: true,
    },
    selfCultivatedAreas: {
      required: true,
    },
  },
  data() {
    return {
      animalsData: animalsData,
      hectareLimits: hectareLimits
    }
  },
  computed: {
    hectareAmount() {
      return this.selfCultivatedAreas / 10000;
    },
    totalAnimalUnits() {
      const animalUnits = Object.entries(this.animals).map(([name, count]) => {
        const parsedCount = parseFloat(count) || 0;
        const vePerAnimal = parseFloat(this.getVEForAnimal(name)) || 0;
        const totalVE = parseFloat((parsedCount * vePerAnimal).toFixed(4));
        return {
          name,
          title: this.getAnimalTitle(name),
          count: parsedCount,
          totalVE,
          group: this.getAnimalGroup(name),
        };
      });

      return animalUnits;
    },
    group1Animals() {
      return this.totalAnimalUnits
        .filter(animal => animal.group === 1)
        .sort((a, b) => a.totalVE - b.totalVE);
    },
    group2Animals() {
      return this.totalAnimalUnits
        .filter(animal => animal.group === 2)
        .sort((a, b) => a.totalVE - b.totalVE);
    },
    combinedAnimals() {
      let animals = [...this.group1Animals, ...this.group2Animals];
      return animals;
    },
    exceedingAnimals() {
      const [exceedingGroup1, remainingHectares] = this.allocateVEToHectares(
        this.combinedAnimals,
        this.hectareAmount,
        this.allocatedVEs
      );

      return exceedingGroup1;
    },
    exceedsLimit() {
      return this.exceedingAnimals.length > 0;
    },
    allocatedVEs() {
      const hectareLimits = [
        { hectares: 20, limit: 10 },
        { hectares: 10, limit: 7 },
        { hectares: 20, limit: 6 },
        { hectares: 50, limit: 3 },
        { hectares: Infinity, limit: 1.5 },
      ];

      let remainingHectares = this.hectareAmount;
      let totalVE = 0;

      for (const { hectares, limit } of hectareLimits) {
        const usableHectares = Math.min(remainingHectares, hectares);
        totalVE += usableHectares * limit;
        remainingHectares -= usableHectares;

        // Break out of the loop if no hectares are left
        if (remainingHectares <= 0) {
          break;
        }
      }

      return totalVE;
    },
  },
  methods: {
    getVEForAnimal(animalName) {
      return animalsData[animalName]?.ve || 0;
    },
    getAnimalGroup(animalName) {
      return animalsData[animalName]?.group || null;
    },
    getAnimalTitle(animalName) {
      return animalsData[animalName]?.title || null;
    },
    allocateVEToHectares(animals, totalHectares, totalAllowedVE) {
      let remainingVE = totalAllowedVE;
      let remainingHectares = totalHectares;
      let unallocatedAnimals = [];

      for (const animal of animals) {
        let ve = animal.totalVE;

        if (ve <= remainingVE) {
          // VE can be accommodated within the remaining capacity
          const hectaresUsed = ve * (totalHectares / totalAllowedVE); // Proportion of hectares used
          remainingVE -= ve;
          remainingHectares -= hectaresUsed;
          ve = 0;
        } else {
          // VE exceeds the remaining capacity
          const usableVE = remainingVE;
          const hectaresUsed = usableVE * (totalHectares / totalAllowedVE);
          remainingVE = 0;
          remainingHectares -= hectaresUsed;
          ve -= usableVE;
        }

        if (ve > 0.001) {
          unallocatedAnimals.push({
            name: animal.name,
            count: animal.count,
            ve: parseFloat(ve.toFixed(4)),
            title: animal.title
          });
        }
      }

      return [unallocatedAnimals, remainingHectares];
    }
  },
}
</script>

<style scoped lang="scss"></style>
