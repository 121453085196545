import {i18n} from '@/plugins/i18n';

export const taxModels = [
  {
    model: "Bundesmodell",
    modelForModal: i18n.t('properties.tax_model_for_modal.bundesmodell'),
    stateCodes: ["3", "4", "5", "8", "10", "11", "13", "15", "16"]
  },
  {
    model: "Modifiziertes Bundesmodell",
    modelForModal: i18n.t('properties.tax_model_for_modal.modifiziertes_bundesmodell'),
    stateCodes: ["12", "14"]
  },
  {
    model: "Modifiziertes Bodenwertmodell",
    modelForModal: i18n.t('properties.tax_model_for_modal.modifiziertes_bundesmodell'),
    stateCodes: ["1"]
  },
  {
    model: "Flächen-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.flächen_modell'),
    stateCodes: ["2"]
  },
  {
    model: "Flächen-Faktor-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.flächen_faktor_modell'),
    stateCodes: ["7"]
  },
  {
    model: "Flächen-Lage-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.flächen_lage_modell'),
    stateCodes: ["9"]
  },
  {
    model: "Wohnlagen-Modell",
    modelForModal: i18n.t('properties.tax_model_for_modal.wohnlagen_modell'),
    stateCodes: ["6"]
  }
];

export const animalsData = {
  alpacas: {
    ve: 0.08,
    group: 1,
    title: 'Alpakas'
  },
  damAnimal_below1Year: {
    ve: 0.04,
    group: 1,
    title: 'Damtiere unter 1 Jahr'
  },
  damAnimal_above1Year: {
    ve: 0.08,
    group: 1,
    title: 'Damtiere 1 Jahr und älter'
  },
  layingHens_layingHensIncludeBreeding: {
    ve: 0.02,
    group: 2,
    title: 'Legehennen (einschließlich einer normalen Aufzucht zur Ergänzung des Bestandes)'
  },
  layingHens_purchasedLayingHens: {
    ve: 0.0183,
    group: 2,
    title: 'Legehennen aus zugekauften Junghennen'
  },
  poultry: {
    ve: 0.04,
    group: 2,
    title: 'Zuchtputen, -enten, -gänse'
  },
  breedingRabbits: {
    ve: 0.025,
    group: 2,
    title: 'Zucht- und Angorakaninchen'
  },
  llamas: {
    ve: 0.1,
    group: 1,
    title: 'Lamas'
  },
  horses_below3YearsOrSmallHorses: {
    ve: 0.7,
    group: 1,
    title: 'Pferde unter 3 Jahren und Kleinpferde'
  },
  horses_horses: {
    ve: 1.1,
    group: 1,
    title: 'Pferde 3 Jahre und älter'
  },
  beef_below1Year: {
    ve: 0.3,
    group: 1,
    title: 'Kälber und Jungvieh unter 1 Jahr (einschließlich Mastkälber, Starterkälber und Fresser)'
  },
  beef_1to2Year: {
    ve: 0.7,
    group: 1,
    title: 'Jungvieh 1 bis 2 Jahre alt'
  },
  beef_above2Year: {
    ve: 1,
    group: 1,
    title: 'Färsen (älter als 2 Jahre)'
  },
  fatteningAnimalsLessThan1Year: {
    ve: 1,
    group: 1,
    title: 'Masttiere (Mastdauer weniger als 1 Jahr)'
  },
  cows: {
    ve: 1,
    group: 1,
    title: 'Kühe (einschließlich Mutter- und Ammenkühe mit den dazugehörigen Saugkälbern)'
  },
  breedingBulls: {
    ve: 1.2,
    group: 1,
    title: 'Zuchtbullen, Zugochsen'
  },
  sheep_below1Year: {
    ve: 0.05,
    group: 1,
    title: 'Schafe unter 1 Jahr (einschließlich Mastlämmer)'
  },
  sheep_above1Year: {
    ve: 0.1,
    group: 1,
    title: 'Schafe 1 Jahr und älter'
  },
  breedingPigs: {
    ve: 0.33,
    group: 2,
    title: 'Zuchtschweine (einschließlich Jungzuchtschweine über etwa 90 kg)'
  },
  ostriches_above14Months: {
    ve: 0.32,
    group: 1,
    title: 'Strauße (Zuchttiere 14 Monate und älter)'
  },
  ostriches_below14Months: {
    ve: 0.25,
    group: 1,
    title: 'Strauße (Jungtiere/Masttiere unter 14 Monate)'
  },
  goats: {
    ve: 0.08,
    group: 1,
    title: 'Ziegen'
  },
  youngChickensForFattening_below6Passes: {
    ve: 0.0017,
    group: 2,
    title: 'Jungmasthühner (bis zu 6 Durchgänge je Jahr – schwere Tiere)'
  },
  youngChickensForFattening_above6Passes: {
    ve: 0.0013,
    group: 2,
    title: 'Jungmasthühner (mehr als 6 Durchgänge je Jahr – leichte Tiere)'
  },
  pullets: {
    ve: 0.0017,
    group: 2,
    title: 'Junghennen'
  },
  fatteningDucks_count: {
    ve: 0.0033,
    group: 2,
    title: 'Mastenten'
  },
  fatteningDucks_raisingPhase: {
    ve: 0.0011,
    group: 2,
    title: 'Mastenten in der Aufzuchtphase'
  },
  fatteningDucks_fatteningPhase: {
    ve: 0.0022,
    group: 2,
    title: 'Mastenten in der Mastphase'
  },
  turkeys_selfRaised: {
    ve: 0.0067,
    group: 2,
    title: 'Mastputen aus selbst erzeugten Jungputen'
  },
  turkeys_bought: {
    ve: 0.005,
    group: 2,
    title: 'Mastputen aus zugekauften Jungputen'
  },
  turkeys_youngTurkeys: {
    ve: 0.0017,
    group: 2,
    title: 'Jungputen (bis etwa 8 Wochen)'
  },
  fattenedGeese: {
    ve: 0.0067,
    group: 2,
    title: 'Mastgänse'
  },
  mastRabbit: {
    ve: 0.0025,
    group: 2,
    title: 'Mastkaninchen'
  },
  beefCattle: {
    ve: 1,
    group: 1,
    title: 'Rindvieh - Masttiere (Mastdauer 1 Jahr und mehr)'
  },
  pigs_lightPiglets: {
    ve: 0.01,
    group: 2,
    title: 'Leichte Ferkel (bis etwa 12 kg)'
  },
  pigs_piglets: {
    ve: 0.02,
    group: 2,
    title: 'Ferkel (über etwa 12 bis etwa 20 kg)'
  },
  pigs_heavyPiglets: {
    ve: 0.04,
    group: 2,
    title: 'Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)'
  },
  pigs_runner: {
    ve: 0.06,
    group: 2,
    title: 'Läufer (über etwa 30 bis etwa 45 kg)'
  },
  pigs_heavyRunner: {
    ve: 0.08,
    group: 2,
    title: 'Schwere Läufer (über etwa 45 bis etwa 60 kg)'
  },
  pigs_fatteningPigs: {
    ve: 0.16,
    group: 2,
    title: 'Mastschweine'
  },
  pigs_youngBreedingPigs: {
    ve: 0.12,
    group: 2,
    title: 'Jungzuchtschweine bis etwa 90 kg'
  },
  boughtPigs_lightPiglets: {
    ve: 0.01,
    group: 2,
    title: 'Leichte Ferkel (bis etwa 12 kg)'
  },
  boughtPigs_piglets: {
    ve: 0.02,
    group: 2,
    title: 'Ferkel (über etwa 12 bis etwa 20 kg)'
  },
  boughtPigs_heavyPiglets: {
    ve: 0.04,
    group: 2,
    title: 'Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)'
  },
  boughtPigs_runner: {
    ve: 0.06,
    group: 2,
    title: 'Läufer (über etwa 30 bis etwa 45 kg)'
  },
  boughtPigs_heavyRunner: {
    ve: 0.08,
    group: 2,
    title: 'Schwere Läufer (über etwa 45 bis etwa 60 kg)'
  },
};
