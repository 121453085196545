<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('components.dms.header')" :logo="require('@/assets/dms_title.png')"/>

    <div v-if="renderStart">
      <div class="p-3 mt-2 mb-2 info-msg flex">
        <img src="@/assets/note.svg" width="15px">
        <label class="pl-4 text-left">
          {{ $t('components.dms.intro') }} <a href="https://hilfe.grundsteuer-digital.de/faq/uebergabe-von-dokumenten-an-das-datev-dokumentenmanagement/" target="_blank">{{ $t('components.dms.intro2') }}</a>.
        </label>
      </div>
      <div class="align-left">
        <Button :text="$t('components.dms.letsgo')" @click="handleStartClick"
                :icon="getAssetPath('documents.svg')"></Button>
      </div>
    </div>
    <div class="mr-7">
      <AppGrid grid-id="datev-dms-transfer"
               data-endpoint="datev/dms_list"
               v-if="renderGrid"
               :standard-filters="standardFilters"
               default-sort-attr="filename"
               :default-sort-dir="1"
               :columns="columns"
               :selectable="true"
               :show-select-col="true"
               :show-filter="true"
               selection-data-key="prim_uid"
               :use-local-data="false"
               :resizable-columns="true"
               :reorderable-columns="true"
               :auto-load="true"
               :show-create-record="false"
               :show-dms-notice="true"
               :menus="menus"
               @menu-clicked="handleMenuClick"
               @row-selection="handleRowSelect"
               @row-select-all="handleRowSelect"
               @row-unselect="handleRowSelect"
               @row-unselect-all="handleRowSelect"
               @on-data-loaded="handleDataLoaded"
      />
    </div>
  </div>
</template>

<script>
import AppGrid from "../AppGrid";
import {i18n} from "@/plugins/i18n";

export default {
  name: "Dms",
  components: {AppGrid},
  data() {
    return {
      renderStart: true,
      renderGrid: false,
      dmsDocuments: {},
      dmsMapper: {},
      selected: [],
      taxSoftware: '',
      standardFilters: [
        {
          selected: true,
          default: true,
          name: this.$t('documents.attrs.datev_dms.filter_ready'),
          id: 'dms_not_transferred'
        }, {
          selected: false,
          default: false,
          name: this.$t('documents.attrs.datev_dms.all_documents'),
          id: 'dms_all_documents'
        },
      ],
      columns: [
        {
          field: 'client',
          header: i18n.t('documents.attrs.client'),
          elType: 'text',
          isChecked: true,
          sortable: false
        }, {
          field: 'uid',
          header: i18n.t('documents.attrs.externalId'),
          isChecked: true,
          elType: 'text',
          width: 200
        }, {
          field: 'filename',
          header: i18n.t('documents.attrs.filename'),
          isChecked: true,
          elType: 'text',
          width: 300,
          clickable: false,
          prependIcon: true,
          getIcon: (row) => {
            switch (row.fileext) {
              case "xls,xlsx":
                return require('@/assets/document_xls.svg')
              case "doc,docx":
                return require('@/assets/document_doc.svg')
              case "pdf":
                return require('@/assets/document_pdf.svg')
              default:
                return require('@/assets/document_generic.svg')
            }
          },
        }, {
          field: 'property',
          header: i18n.t('documents.attrs.property'),
          elType: 'text',
          isChecked: true,
          width: 300
        }, {
          field: 'description',
          header: i18n.t('documents.attrs.description'),
          placeholder: i18n.t('documents.attrs.description'),
          colElType: 'text',
          isChecked: true,
          sortable: false,
          width: 300
        }, {
          field: 'filesize',
          header: i18n.t('documents.attrs.file_size'),
          isChecked: true,
          sortable: false
        }, {
          field: 'declarationStatus',
          header: i18n.t('components.dms.headers.declaration_status'),
          filterLbl: i18n.t('components.dms.headers.declaration_status'),
          filterOptions: this.$t('components.dms.statusOptions'),
          elType: 'select',
          isChecked: true,
          sortable: false
        }, {
          field: 'uploadedAt',
          header: i18n.t('documents.attrs.uploaded_at'),
          isChecked: false,
          sortable: true
        },{
          field: 'parentType',
          header: i18n.t('components.dms.headers.document_source'),
          filterLbl: i18n.t('components.dms.headers.document_source'),
          filterOptions: this.$t('components.dms.document_sources_select'),
          elType: 'select',
          isChecked: false,
          sortable: true
        },{
          field: 'documentType',
          header: i18n.t('components.dms.headers.type'),
          filterLbl: i18n.t('components.dms.headers.type'),
          filterOptions: this.$t('components.dms.document_types'),
          elType: 'select',
          isChecked: false,
          sortable: true
        },{
          field: 'datevTransferredAt',
          header: i18n.t('components.dms.headers.transferred_at'),
          filterLbl: i18n.t('components.dms.headers.transferred_at'),
          filterOptions: i18n.t('components.dms.status_select'),
          elType: 'select',
          isChecked: true,
          width: 200,
        }, {
          field: 'status',
          header: i18n.t('documents.attrs.status'),
          isChecked: true,
          colElType: 'transferstatus',
          idField: 'prim_uid',
          width: 200,
          sortable: false
        }
      ],
      menus: [
        {
          id: "dms.back",
          "icon": this.getAssetPath('arrow-back.svg'),
          "title": this.$t('documents.attrs.datev_dms.back')
        }, {
          id: "dms.transfer",
          "icon": this.getAssetPath('upload.svg'),
          "icon_disabled": this.getAssetPath('upload.svg'),
          "title": this.$t('documents.attrs.datev_dms.transfer'),
          "enable_on_select": true,
          "disabled": true,
          "action_per_record": "transfer-record"
        }
      ]
    }
  },
  computed: {},
  created() {
    if(!this.hasAccess('dmstransfer')) {
      this.$router.push('/home');
    }
  },
  mounted() {
    if(this.$route.params.oauth && this.$route.params.oauth === 'completed') {
      this.handleStartClick();
    }
    this.taxSoftware = this.getCurrentUser().settings.tax_office_software;
    this.dmsEnabled = this.getCurrentUser().settings.tax_office_software;
    if(this.$route.params.hideInfo) {
      this.renderStart = false;
      this.handleStartClick();
    }
  },
  methods: {
    handleStartClick() {
      this.renderStart = false;
      this.$api.trigger('/datev/dms').then((response) => {
        if(response.data.oauthUrl) {
          window.location.href = response.data.oauthUrl;
        } else {
          this.renderGrid = true;
        }
        if(response.data.records) {
          //@TODO: if success = false or empty result do error
          let dmsDocuments = Object.values(response.data.records);
          this.dmsDocuments = dmsDocuments.map((o) => {
            o.status = '-';
            o.description = '';
            if (o.parentType === 'client') {
              o.description = o.client + ' - ' + o.baseFilename;
            } else {
              o.description = o.property + ' - ' + o.baseFilename;
            }

            if (o.uploadedAt > 1) {
              let date = new Date(o.uploadedAt*1000);
              o.uploadedAt = date.toLocaleDateString();
            }

            if (o.datevTransferredAt > 1) {
              let date = new Date(o.datevTransferredAt*1000);
              o.datevTransferredAt = i18n.t('components.dms.status.already_transferred') + ' ' + date.toLocaleDateString();
            } else {
              o.datevTransferredAt = i18n.t('components.dms.status.not_transferred');
            }

            if (o.declarationStatus.length > 1) {
              o.declarationStatus = i18n.t('declarations.stages.' + o.declarationStatus);
            }

            if (o.parentType.length > 1) {
              o.parentType = i18n.t('components.dms.document_sources.' + o.parentType);
            }

            return o;
          });
          for (let i = 0; i < this.dmsDocuments.length; i++) {
            this.dmsMapper[this.dmsDocuments[i].prim_uid] = i;
          }
          this.renderGrid = true;
        }
      });
    },
    handleBackClick() {
      this.renderGrid = false;
      this.dmsDocuments = {};
      this.dmsMapper = [];
      this.renderStart = true;
    },
    handleRowSelect(rows) {
      this.selected = rows;
    },
    handleMenuClick(args) {
      switch (args.menuId) {
        case 'dms.back':
          this.handleBackClick();
          break;
      }
    },
    handleDataLoaded(data) {
      data.map((o) => {
        o.status = '-';
        o.description = '';
        if (o.parentType === 'client') {
          o.description = o.client + ' - ' + o.baseFilename;
        } else {
          o.description = o.property + ' - ' + o.baseFilename;
        }

        if (o.uploadedAt > 1) {
          let date = new Date(o.uploadedAt*1000);
          o.uploadedAt = date.toLocaleDateString();
        }

        if (o.datevTransferredAt > 1) {
          let date = new Date(o.datevTransferredAt*1000);
          o.datevTransferredAt = i18n.t('components.dms.status.already_transferred') + ' ' + date.toLocaleDateString();
        } else {
          o.datevTransferredAt = i18n.t('components.dms.status.not_transferred');
        }

        if (o.declarationStatus.length > 1) {
          o.declarationStatus = i18n.t('declarations.stages.' + o.declarationStatus);
        }

        if (o.parentType.length > 1) {
          o.parentType = i18n.t('components.dms.document_sources.' + o.parentType);
        }

        return o;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.info-msg {
  background-color: #f2f2f2;

  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;

    &.underline {
      text-decoration: underline;

    }
  }

  .border-left {
    border-left: 1px solid #dbdbdb;
  }
}

.align-left{
  text-align: left;
}
</style>
