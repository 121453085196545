<template>
  <div class="h-full bg-white pl-7 w-full table_15 ">
    <PageHeader :title="$t('settings.billing.header_text')" :breadcrumbs="breadcrumbs" :showBottomLine="false"/>

    <BillingThanksModal
      :visible="showBillingThanksModal"
      @close="showBillingThanksModal = false"
      @continue="() => {
        if (!this.isGetMyInvoicesConnected) {
          showGmiIntroductionModal = true
        }
      }"
    />

    <GmiIntroductionModal
      :visible="showGmiIntroductionModal"
      @close="showGmiIntroductionModal = false"
    />

    <ModalDialog v-show="isModalPayVisible" :headerText="$t('settings.billing.pay_now')" @close="handleModalPayClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start  space-y-4 mt-8">
          <table class=" ">
            <tr>
              <td><label class="text">{{ $t('settings.billing.recipient') }}:</label></td>
              <td class="text-left  py-2">
                <div class="w-full   flex justify-between items-center text-left bg-green disabled-box">
                  <label class="disabled-text ">{{ pay_info }}</label>
                  <img class="img  cursor-pointer" @click="copyText(pay_info)" :src="getAssetPath('copy.svg')"/>
                </div>
              </td>
            </tr>

            <tr>
              <td><label class="text">IBAN:</label></td>
              <td class="text-left  py-2">
                <div class="w-full   flex justify-between items-center text-left bg-green disabled-box">
                  <label class="disabled-text ">{{ iban_number }}</label>
                  <img class="img  cursor-pointer" @click="copyText(iban_number)" :src="getAssetPath('copy.svg')"/>
                </div>
              </td>
            </tr>

            <tr>
              <td><label class="text">BIC:</label></td>
              <td class="text-left  py-2">
                <div class="w-full   flex justify-between items-center text-left bg-green disabled-box">
                  <label class="disabled-text ">{{ bic_code }}</label>
                  <img class="img  cursor-pointer" @click="copyText(bic_code)" :src="getAssetPath('copy.svg')"/>
                </div>
              </td>
            </tr>
            <tr>
              <td><label class="text">{{ $t('settings.billing.amount') }} in €:</label></td>
              <td class="text-left  py-2">
                <div class="w-full   flex justify-between items-center text-left bg-green disabled-box">
                  <label class="disabled-text ">{{ amount }}</label>
                  <img class="img  cursor-pointer" @click="copyText(OnlyAmount)" :src="getAssetPath('copy.svg')"/>
                </div>
              </td>
            </tr>

            <tr>
              <td><label class="text">{{ $t('settings.billing.purpose_use') }}:</label></td>
              <td class="text-left  py-2">
                <div class="w-full   flex justify-between items-center text-left bg-green disabled-box">
                  <label class="disabled-text ">{{ invoice_number }}</label>
                  <img class="img  cursor-pointer" @click="copyText(invoice_number)" :src="getAssetPath('copy.svg')"/>
                </div>
              </td>
            </tr>
          </table>
          <div class="flex justify-end  ">
            <Button class="w-1/3 mt-4 mb-8" text="OK" @click="isModalPayVisible=false" :secondary="true"/>
          </div>
        </div>
      </template>
    </ModalDialog>

    <ChangeSubscriptionModal
      :visible="showChangeSubscriptionModal"
      :changeSubscriptionAfterEndTerm="changeSubscriptionAfterEndTerm"
      @close="closeChangeSubscriptionModal"
    />

    <ModalDialogBilling :showClose="!subscriptionExpired" v-show="isModalVisible" @close="handleModalClose" class="modal_wide" style="overflow-y: visible;" :class="active">
      <template v-slot:body>
        <div style="max-height: calc(100vh - 100px); overflow-y: auto; display: block;">
          <div class="flex flex-col justify-start ml-4 mr-6" v-if="showForm=='plan'">
            <div class="form_grp">
              <div class="mod_label">{{ $t('billing_trial.end_term') }}</div>
              <div class="flex flex-col space-y-3 mt-8 w-full">
                <div
                  class="flex justify-start items-center cursor-pointer min-height"
                  @click="createOptionSelected('renewal')"
                >
                  <RadioButton type="radio" name="laufzeit" value="renewal" v-model="laufzeit" checked/>
                  <span class="ml-3 mt-1 textradio">{{ $t('billing_trial.renewal') }}</span>
                </div>

                <div
                  class="flex justify-start items-center cursor-pointer min-height"
                  @click="() => {
                    createOptionSelected('another_package');
                    openChangeSubscriptionModal(true);
                  }"
                >
                  <div class="flex">
                    <RadioButton type="radio" name="laufzeit" value="another_package" v-model="laufzeit"/>
                    <span class="ml-3 mt-1 textradio mr-4"> {{ $t('billing_trial.another_package') }}</span>
                  </div>
                </div>

                <div
                  class="flex justify-start items-center cursor-pointer min-height"
                  @click="createOptionSelected('termination')"
                >
                  <RadioButton type="radio" name="laufzeit" value="termination" v-model="laufzeit"/>
                  <span class="ml-3 mt-1 textradio"> {{ $t('billing_trial.termination') }}</span>
                </div>
              </div>
              <div class="flex justify-end w-full">
                <div class="flex justify-end mb-4 mt-4 space-x-4">
                  <Button :text="$t('buttons.cancel')" :secondary="true" @click="isModalVisible=false"></Button>
                  <Button @click='submit(laufzeit)' :text="laufzeit === 'termination' ? $t('buttons.next') : $t('buttons.save')"></Button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-start ml-4 mr-4" v-if="showForm=='payment_type'">
            <div class="form_grp">

              <div class="mod_label">{{ $t('billing_subscribe.payment_method') }}</div>
              <div class="flex flex-col space-y-6 mt-8 w-full">
                <div class="flex space-x-4">
                  <div class="flex justify-start items-center">
                    <RadioButton type="radio" name="Zahlungsart" value="direct_debit"
                                 v-model="payment_method.debit_charge"
                                 checked/>
                    <span class="ml-2 textradio"> {{ $t('billing_subscribe.direct_debit') }}</span>
                  </div>
                  <div class="flex justify-start items-center ">
                    <RadioButton type="radio" name="Zahlungsart" value="bank_transfer"
                                 v-model="payment_method.debit_charge"/>
                    <span class="ml-2 textradio"> {{ $t('billing_subscribe.bank_transfer') }}</span>
                  </div>
                </div>

                <div class="  flex justify-start space-x-4">
                  <div class="w-full">
                    <InputText class="w-full" v-model="payment_method.account_owner" type="text"
                               :label="$t('billing_subscribe.account_owner')"
                               :isError="account_ownererror"
                               :errorMessage="$t('billing_subscribe.errors.acc_owner_required')" :isRequired="true"
                               :placeholder="$t('billing_subscribe.account_owner_name')"
                               :disabled="payment_method.debit_charge==='bank_transfer'"/>
                    <!--                  <span v-if="account_ownererror"
                          class="err">{{ $t('billing_subscribe.errors.acc_owner_required') }}</span>-->
                  </div>
                  <div class="w-full">
                    <InputText class="w-full" v-model="payment_method.iban" type="text" label="IBAN" :isRequired="true"
                               :isError="ibanerror||iban_empty_error" :errorMessage="getErrorMessage"
                               placeholder="272726252525555" :disabled="payment_method.debit_charge==='bank_transfer'"/>
                    <!--                    <span v-if="ibanerror" class="err">{{$t('billing_subscribe.errors.ibanerror')}}</span>-->
                    <!--                    <span v-if="iban_empty_error" class="err">{{$t('billing_subscribe.errors.ibanrequired')}} </span>-->
                  </div>
                  <div class="w-full">
                    <InputText class="w-full" :isError="bicerror"
                               :errorMessage="$t('billing_subscribe.errors.bic_required')" v-model="payment_method.bic"
                               type="text" label="BIC" :isRequired="true"
                               :disabled="payment_method.debit_charge==='bank_transfer'"/>
                    <!--                    <span v-if="bicerror" class="err">{{$t('billing_subscribe.errors.bic_required')}}</span>-->

                  </div>
                </div>
              </div>

              <div class="flex justify-end w-full mt-4">
                <div class="flex justify-end  mt-6 space-x-4">
                  <Button :text="$t('buttons.cancel')" :secondary="true" @click="isModalVisible=false"></Button>
                  <Button @click='updatePaymentMethod' :text="$t('buttons.save')"></Button>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-start ml-4 mr-4" v-if="showForm==false">
            <div class="dialog-header ">{{ $t('billing_subscribe.bill_address') }}</div>
            <div class="form_detail space-y-4">
              <div class="flex justify-between space-x-8">
                <div class="w-full">
                  <InputText class="w-full"
                             type="text"
                             :label="$t('billing_subscribe.company')"
                             v-model='form.company'
                             :isRequired="true"/>
                  <span v-if="form.errors().has('company')" v-text="form.errors().get('company')" class="err"></span>
                </div>
                <div class="w-full">
                  <InputText class="w-full"
                             type="text"
                             :label="$t('billing_trial.email_invoice')"
                             v-model='form.email'/>
                </div>
              </div>
              <div class="flex justify-between space-x-8">
                <div class="w-full">
                  <InputSelect v-model="form.salutation"
                               :selectedValue="form.salutation"
                               :options="salutation"
                               :label="$t('billing_subscribe.salutation')"
                               :isRequired="true"></InputSelect>
                  <span v-if="form.errors().has('salutation')" v-text="form.errors().get('salutation')" class="err"></span>
                </div>
                <div class="w-full">
                  <InputText class="w-full"
                             v-model='form.first_name'
                             type="text"
                             :label="$t('billing_subscribe.first_name')"
                             :isRequired="true"
                             placeholder="Timo"/>
                  <span v-if="form.errors().has('first_name')" v-text="form.errors().get('first_name')" class="err"></span>
                </div>
                <div class="w-full">
                  <InputText class="w-full"
                             v-model='form.last_name'
                             type="text"
                             :label="$t('billing_subscribe.last_name')"
                             :isRequired="true"
                             placeholder="Tauscher"/>
                  <span v-if="form.errors().has('last_name')" v-text="form.errors().get('last_name')" class="err"></span>
                </div>
              </div>

              <div class="flex justify-between space-x-8">
                <div class="w-full">
                  <InputText class="w-full"
                             v-model='form.street'
                             type="text"
                             :label="$t('billing_subscribe.street')"
                             placeholder="Kurparkhotel Bad"/>
                  <span v-if="form.errors().has('street')" v-text="form.errors().get('street')" class="err"></span>

                </div>
                <div class="w-full">
                  <InputText class="w-full"
                             v-model='form.house_number'
                             type="text"
                             :label="$t('billing_subscribe.house_number')"/>
                  <span v-if="form.errors().has('house_number')" v-text="form.errors().get('house_number')" class="err"></span>
                </div>
              </div>

              <div class="flex justify-between space-x-8 mb-8">
                <div class="w-full">
                  <InputText class="w-full"
                             type="text" v-model='form.zip'
                             :label="$t('billing_subscribe.zip')"
                             :isZip="true"
                             :zipCountry="form.country"
                             :isRequired="true"/>
                  <span v-if="form.errors().has('zip')" v-text="form.errors().get('zip')" class="err"></span>
                </div>
                <div class="w-full">
                  <InputText class="w-full"
                             type="text" v-model='form.city'
                             :label="$t('billing_subscribe.city')"
                             :isRequired="true"
                             placeholder="Kassel"/>
                  <span v-if="form.errors().has('city')" v-text="form.errors().get('city')" class="err"></span>
                </div>
                <div class="w-full">
                  <InputSelect v-model='form.country'
                               :selectedValue="country_uid"
                               :options="countries"
                               :label="$t('billing_subscribe.country')"
                               :filterable="true"
                               :isRequired="true"
                               :change="onChangeCountry()"
                               placeholder=""/>
                  <span v-if="form.errors().has('country')" v-text="form.errors().get('country')" class="err"></span>
                </div>
              </div>

              <div class="flex justify-between space-x-8" v-if="form.country != 50">
                <div class="w-full">
                  <InputText class="w-full"
                             type="text"
                             v-model='form.vat_registration_id'
                             :label="$t('billing_subscribe.vat_registration_id')"
                             :isRequired="true"/>
                  <span v-if="form.errors().has('vat_registration_id')" v-text="form.errors().get('vat_registration_id')" class="err"></span>
                </div>
                <div class="w-full invisible"></div>
                <div class="w-full invisible"></div>
              </div>

              <div class="flex justify-between space-x-8 mb-8">
                <div class="w-full">
                  <InputText
                    class="w-full"
                    type="text"
                    v-model='form.billing_xrechnung_email_address'
                    :label="$t('billing_subscribe.billing_xrechnung_email_address')"
                    :showHelpIconPopover="true"
                    :popoverTexts="[$t('billing_subscribe.billing_xrechnung_email_address_note')]"
                  />
                </div>
                <div class="w-full">
                  <InputText
                    class="w-full"
                    type="text"
                    v-model='form.billing_xrechnung_buyer_reference'
                    :label="$t('billing_subscribe.billing_xrechnung_buyer_reference')"
                    :showHelpIconPopover="true"
                    :popoverTexts="[$t('billing_subscribe.billing_xrechnung_buyer_reference_note')]"
                  />
                </div>
                <div class="w-full">
                  <InputText
                    class="w-full"
                    type="text"
                    v-model='form.billing_xrechnung_vendor_number'
                    :label="$t('billing_subscribe.billing_xrechnung_vendor_number')"
                    :showHelpIconPopover="true"
                    :popoverTexts="[$t('billing_subscribe.billing_xrechnung_vendor_number_note')]"
                  />
                </div>
              </div>

              <div class="flex justify-end space-x-4 ">
                <Button class="w-44 mt-8"
                        :text="$t('buttons.cancel')"
                        @click="handleModalClose"
                        :secondary="true"></Button>
                <Button class="mt-8"
                        @click='updateContact'
                        :text="$t('buttons.save')"></Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalDialogBilling>

    <PlanCancellationModal
      :visible="showPlanCancellationModal"
      @close="handlePlanCancellationModalClose"
    />

    <div class="flex justify-end items-center -mb-5 mr-14">
      <label class="text-right-id">{{ $t('billing_trial.gstb_number') }}: {{ this.getCurrentUser().account_id }}</label>
    </div>

    <div class="w-full pr-7 mt-8 ">
      <div class="flex justify-around space-x-10 mr-6">
        <!--            <InputText class="w-full" label="First Name" :isRequired="true" placeholder=""/>-->

        <div class="rectangle w-full ">
          <div class="  ">
            <div class="flex  justify-between items-center h-14 ml-5 ">
              <label class="text-left text w-full"> {{ $t('billing_trial.current_package') }}:
                {{ this.planDetail.plan_name }} <span
                    class="text-color billing-text cursor-pointer"
                    @click='openChangeSubscriptionModal()'>({{ $t('billing_subscribe.to_change') }})</span>
              </label>

              <label class="w-52 -mt-1 min_30">
                {{ $t('billing_trial.end_term') }}:
                <span v-if="this.planDetail.after_end_term !== null">{{ $t('billing_trial.after_end_term.' + (this.planDetail.after_end_term ? this.planDetail.after_end_term : 'renewal')) }} </span>
                <span v-else>{{ $t('billing_trial.after_end_term.renewal') }} </span>
                <span class="billing-text" v-if="this.planDetail.after_end_term == 'another_package'">{{ this.planDetail.after_plan_name.plan_name }} </span>
                <span class="link" @click='term_plan'>({{ $t('billing_subscribe.to_change') }})</span>
              </label>
            </div>

            <hr class=" line"/>
            <!-- content-->
            <div class="rechnungsanschrift grid grid-cols-1 xl:grid-cols-2 gap-4">
              <div class="mt-3 h-full billing-set inline-block">
                <div class="floating text-left mr-8 ml-5 space-y-4">
                  <p class="text">{{ $t('billing_subscribe.base_price') }}:</p>
                  <p class="text">{{ $t('billing_subscribe.price_per_operation') }}:</p>
                  <p class="text">{{ $t('billing_trial.minimum_turnover') }}: </p>
                  <p class="text">{{ $t('billing_trial.contract_period') }}: </p>

                </div>
                <div class="floating text-left mr-8 ml-5 space-y-4">
                  <p class="text-2">{{ this.planDetail.plan_price }} €</p>
                  <p class="text-2">
                    <span v-if="this.planDetail.price_per_unit !== ''">{{ this.planDetail.price_per_unit }}</span>
                    <span v-else>{{ this.planDetail.elster_price }}</span>
                    €
                  </p>
                  <p v-if="!$isBusiness" class="text-2">{{ this.planDetail.minimum_turnover }} <span
                      v-if="this.planDetail.minimum_turnover !=='-'"> €</span></p>
                  <p v-else class="text-2"> - </p>
                  <p v-if="!$isBusiness" class="text-2">{{ this.planDetail.contract_period }}
                    {{ $t('billing_trial.months') }}</p>
                  <p v-else class="text-2">12 {{ $t('billing_trial.months') }}</p>
                </div>
              </div>
              <div class="mt-3 h-full inline-block billing-set">
                <div class="floating text-left mr-8 ml-5 space-y-4">
                  <p v-if="$isBusiness" class="text">{{ $t('billing_trial.maximum_plot') }}:</p>
                  <p class="text">{{ $t('billing_trial.valid_from') }}:</p>
                  <p class="text">{{ $t('billing_trial.expiry_date') }}:</p>
                  <p class="text">{{ $t('billing_subscribe.payment_method') }}:</p>
                </div>
                <div class="floating text-left mr-8 ml-5 space-y-4">
                  <p v-if="$isBusiness" class="text-2">{{ $t('billing_trial.' + this.planDetail.number_of_plot) }} </p>
                  <p class="text-2">{{ this.planDetail.start_date }} </p>
                  <p class="text-2">{{ this.planDetail.end_date }}</p>
                  <p class="text-2" v-if="this.planDetail.payment_mode">{{ $t('billing_subscribe.' + this.planDetail.payment_mode) }} <span
                      class="text-color font-semibold cursor-pointer billing-text"
                      @click="changePaymentMethod">({{ $t('billing_subscribe.to_change') }})</span></p>
                  <!-- <p v-if="this.planDetail.payment_mode=='direct_debit'" @click="generatePdf()" class="text-color text-underline font-size-13">{{$t('settings.billing.download_sepa')}}</p> -->
                </div>
              </div>
            </div>


          </div>

        </div>
        <!-- <InputText class="w-full pr-6" label="Last Name" :isRequired="true" placeholder=""/>-->

      </div>
      <br>
      <div class="flex justify-around space-x-10 mr-6">
        <!--            <InputText class="w-full" label="First Name" :isRequired="true" placeholder=""/>-->

        <div class="rectangle w-full mt-2 ">
          <div class="  ">
            <div class="flex  justify-between items-center h-14 ml-5">
              <label class="text-left text w-full">
                {{ $t('billing_subscribe.billing_details') }}
              </label>
              <img class="  edit edit-icon cursor-pointer " @click='openpopup' :src="getAssetPath('editpen.svg')"
              />
            </div>

            <hr class=" line"/>
            <!-- content-->
            <div class="rechnungsanschrift grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 px-4">
              <div class="mt-3 h-full">
                <h3 class="text-sm font-semibold border-b border-b-gray_dark pb-2 mb-2">
                  {{ $t('billing_subscribe.bill_address') }}
                </h3>

                <table>
                  <tbody>
                    <tr>
                      <td class="p-1">{{ this.form.company }}</td>
                    </tr>
                    <tr>
                      <td class="p-1">{{ this.form.street }} {{ this.form.house_number }}</td>
                    </tr>
                    <tr>
                      <td class="p-1">{{ this.form.zip }} {{ this.form.city }}</td>
                    </tr>
                    <tr>
                      <td class="p-1">{{ country_name }}</td>
                    </tr>
                    <tr v-if="this.country_uid != 50">
                      <td class="p-1">{{ this.form.vat_registration_id }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-3 h-full">
                <h3 class="text-sm font-semibold border-b border-b-gray_dark pb-2 mb-2">
                  {{ $t('billing_subscribe.billing_pdf') }}
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <th class="p-1">{{ $t('billing_subscribe.billing_pdf_email_address') }}:</th>
                      <td>{{ this.form.email }}</td>
                    </tr>
                    <tr>
                      <th class="p-1">{{ $t('general.automated_invoice_delivery') }}:</th>
                      <td>
                        <Button
                          class="mt-3 btn-connect-gmi"
                          :class="{'connected-gmi':isGetMyInvoicesConnected, 'w-56':!usingDatevTaxSoftware, 'w-auto':usingDatevTaxSoftware}"
                          :text="gmiConnectBtnLabel"
                          :icon="isGetMyInvoicesConnected ? getAssetPath('connected_gmi.svg') : getAssetPath('White_Plug_Icon.svg')"
                          @click="connectGmi"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-3 h-full">
                <h3 class="text-sm font-semibold border-b border-b-gray_dark pb-2 mb-2">
                  {{ $t('billing_subscribe.billing_xrechnung') }}
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <th class="p-1">{{ $t('billing_subscribe.billing_xrechnung_email_address') }}:</th>
                      <td>{{ this.form.billing_xrechnung_email_address }}</td>
                    </tr>
                    <tr>
                      <th class="p-1">{{ $t('billing_subscribe.billing_xrechnung_buyer_reference') }}:</th>
                      <td>{{ this.form.billing_xrechnung_buyer_reference }}</td>
                    </tr>
                    <tr>
                      <th class="p-1">{{ $t('billing_subscribe.billing_xrechnung_vendor_number') }}:</th>
                      <td>{{ this.form.billing_xrechnung_vendor_number }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
      <br>
      <div class="flex justify-around space-x-10 mr-6 mt-2">
        <div class="rectangle w-full mb-8 ">
          <div class=" w-full  ">
            <div class="flex  justify-between items-center h-14 ml-5">
              <label class="text-left text w-full">
                {{ $t('billing_trial.bills') }}

              </label>
              <!--     <IconLabel class="w-52 -mt-1" :blackText="true" :icon="require('@/assets/edit_user_green.svg')"
                             label="Edit Client"/> -->
            </div>

            <hr class=" line"/>
            <!--              content-->
            <div class="mt-3 h-full w-full ">

              <table class=" table-fixed min-w-full table_def">
                <thead class="">
                <tr>
                  <th scope="col" class=" py-3 pr-3 text-left pl-6   ">
                    {{ $t('billing_trial.bill_number') }}


                  </th>
                  <th scope="col" class="pr-3 py-3 text-left   ">
                    {{ $t('billing_trial.date') }}
                  </th>
                  <th scope="col" class="pr-3 py-3 text-left   ">
                    {{ $t('billing_trial.ammount') }}
                  </th>
                  <th scope="col" class="pr-3 py-3 text-left   ">
                    {{ $t('billing_trial.description') }}
                  </th>
                  <th scope="col" class="pr-3 py-3 text-left   ">
                    {{ $t('billing_trial.payment_status') }}
                  </th>
                  <th style="min-width:21px" ></th>
                  <th></th>
                  <!--              <th scope="col" class="pr-6 py-3 text-left   ">-->
                  <!--                City-->
                  <!--              </th>-->


                </tr>
                </thead>
                <tbody class="bg-white ">
                <tr v-if="!invoiceListLength">
                  <td class=" py-4 whitespace-nowrap   px-6">

                    <div class="flex justify-between">
                      <div class="flex">
                        Noch keine Rechnungen vorhanden.
                      </div>


                    </div>

                  </td>
                </tr>
                <tr v-else v-for="invoice in invoiceList" :key="invoice.prim_uid">
                  <td class=" py-4 whitespace-nowrap   px-6">

                    <div class="flex justify-between">
                      <div class="flex">


                        <p> {{ invoice.invoice_number }}</p>
                      </div>


                    </div>

                  </td>
                  <td class=" pr-3 py-4 whitespace-nowrap text-left">
                    <div class=" ">{{ invoice.invoice_date }}</div>
                  </td>
                  <td class="pr-3 py-4 whitespace-nowrap text-left">
                    <div class=" ">{{ invoice.net_price }} €</div>
                  </td>
                  <td class="pr-6 py-4 whitespace-nowrap text-left">
                    <div class=" " v-html="invoice.description"></div>
                  </td>
                  <td class="pr-3 py-4 whitespace-nowrap text-left tab_res">
                      <div v-if="invoice.cancelled =='1'">{{$t('settings.billing.Cancelled')}}</div>
                    <div v-else-if="invoice.paid !='0'">{{$t('settings.billing.paid')}}</div>
                    <div v-else>
                      <div class="flex ">
                        <label class="text-2 mr-2">{{ $t('settings.billing.unpaid') }}</label>
                        <label class="text-2 link cursor-pointer"
                               v-if="invoice.payment_type_label !='Zahlung per Lastschrift, bitte nicht überweisen' "
                               @click="billPaymentDatail(invoice)">{{ $t('settings.billing.pay_now') }}</label>
                      </div>
                    </div>
                  </td>
                  <td class="pr-3 py-4 whitespace-nowrap text-left">

                    <div class=" "><img class="down-arrow down-arrow-billing cursor-pointer" v-if="invoice.available == 1"
                                        @click="downloadInvoice(invoice.download_link)"
                                        :src="getAssetPath('download.svg')"
                                        label=""/></div>
                  </td>
                  <td class="pr-3 py-4 whitespace-nowrap text-left">
                    <label class="text-2 link cursor-pointer"
                              v-if="invoice.invoice_type == 'declaration'"
                              @click="downloadCSV(exportColumn, invoice.invoice_uid)">{{$t('settings.billing.download_csv')}}</label>
                  </td>

                </tr>

                </tbody>
              </table>


            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import form from 'vuejs-form'
import ModalDialogBilling from '@/components/ModalDialogBilling.vue';
import ChangeSubscriptionModal from '@/components/Billing/ChangeSubscriptionModal.vue';
import BillingThanksModal from '@/components/Billing/BillingThanksModal.vue';
import GmiIntroductionModal from '@/components/Billing/GmiIntroductionModal.vue';
import PlanCancellationModal from '@/components/Billing/PlanCancellationModal.vue';
import InputSelect from "../inputs/InputSelect";
import Tooltip from 'primevue/tooltip';
import RadioButton from 'primevue/radiobutton';
import {mapGetters} from "vuex";
import {BillingService} from "../../services/billing.service";
import {DeclarationService} from "../../services/declaration.service";
import {salutations} from "@/core/constants";
import {UserService} from "@/services/user.service";
import InputText from "../inputs/InputText.vue";

export default {
  name: "Billing",
  components: {
    InputText,
    RadioButton,
    InputSelect,
    ModalDialogBilling,
    ChangeSubscriptionModal,
    BillingThanksModal,
    GmiIntroductionModal,
    PlanCancellationModal,
  },
  directives: {'tooltip': Tooltip},
  data() {
    return {
      choosepackage: 'no',
      helpText: this.$t('billing_trial.helpText'),

      showChangeSubscriptionModal: false,
      changeSubscriptionAfterEndTerm: false,

      isModalVisible: false,
      isModalPayVisible: false,
      showForm: false,
      showBillingThanksModal: false,
      showGmiIntroductionModal: false,
      showPlanCancellationModal: false,

      active: '',
      breadcrumbs: [{
        title: this.$t('settings.system.settings'),
        link: ''
      }, {
        title: this.$t('sidebar.popup.billing'),
        link: ''
      },
      ],
      invoiceListLength: false,
      planDetail: {},
      invoiceData: {},
      invoiceList: [],
      keys: [],
      country_uid: '',
      country_name: '',
      countries: [{code: '50', country_code :'DE', name: 'Deutschland', }],
      after_end_plan_uid: '',
      iban_number: '',
      bic_code: '',
      pay_info: '',
      amount: '',
      OnlyAmount: '',
      invoice_number: '',
      clientCount: '',
      totalBranch: '',
      propertyCount: '',
      payment_method: {
        debit_charge: 'direct_debit',
        iban: '',
        bic: '',
        account_owner: ''
      },
      ibanerror: true,
      iban_empty_error: false,
      account_ownererror: false,
      bicerror: true,
      laufzeit: 'renewal',
      salutation: salutations,
      subscriptionExpired: false,
      form: form({
        company: '',
        email: '',
        salutation: '',
        first_name: '',
        last_name: '',
        street: '',
        house_number: '',
        zip: '',
        city: '',
        country: '',
        country_code: '',
        vat_registration_id: '',
        billing_xrechnung_email_address: '',
        billing_xrechnung_buyer_reference: '',
        billing_xrechnung_vendor_number: ''
      })
          .rules({
            company: 'required',
            salutation: 'required',
            first_name: 'required',
            last_name: 'required',
            zip: 'required',
            city: 'required',
            country: 'required'
          })
          .messages({
            'company.required': this.$t('billing_subscribe.errors.company_required'),
            'email.required': this.$t('billing_subscribe.errors.email_required'),
            'first_name.required': this.$t('billing_subscribe.errors.first_name_required'),
            'last_name.required':this.$t('billing_subscribe.errors.last_name_required'),
            'zip.required': this.$t('billing_subscribe.errors.zip_required'),
            'city.required': this.$t('billing_subscribe.errors.city_required'),
            'country.required': this.$t('billing_subscribe.errors.country_required'),
            'vat_registration_id.required': this.$t('billing_subscribe.errors.vat_registration_id_required')
          }),
      watch: {
        ['form.data']: {
          deep: true,
          immediate: false,
          handler: 'onFormChange'
        }
      },
      exportColumn: [
        {columnOrder: 0, field: 'client_number'},
        {columnOrder: 1, field: 'client_name'},
        {columnOrder: 2, field: 'client_address'},
        {columnOrder: 3, field: 'declaration_number'},
        {columnOrder: 4, field: 'branch_office'},
        {columnOrder: 5, field: 'property_number'},
        {columnOrder: 6, field: 'property_address'}
      ],
    }
  },
  computed: {
    getErrorMessage() {
      if (this.ibanError === true) {
        return this.$t('billing_subscribe.errors.ibanerror')
      } else if (this.iban_empty_error === true) {
        return this.$t('billing_subscribe.errors.ibanrequired')
      } else {
        return ""
      }
    },
    accountIsExpired() {
      if (this.getCurrentUser().subscriptionExpired) {
        return true;
      }

      return false;
    },
    isTaxConsultant() {
      return this.getCurrentUser().account_type === 'tax_consultant';
    },
    usingDatevTaxSoftware() {
      return this.getCurrentUser().settings.tax_office_software === 'datev';
    },
    isGetMyInvoicesConnected() {
      return this.getCurrentUser().gmi_connected && parseInt(this.getCurrentUser().gmi_connected) === 1;
    },
    gmiConnectBtnLabel() {
      if(this.usingDatevTaxSoftware) {
        return this.isGetMyInvoicesConnected ? this.$t('connect_gmi.datev.connected_button_label') : this.$t('connect_gmi.datev.button_label')
      } else {
        return this.isGetMyInvoicesConnected ? this.$t('connect_gmi.others.connected_button_label') : this.$t('connect_gmi.others.button_label')
      }
    },
  },
  mounted() {
    const service = new BillingService()

    this.subscriptionExpired = !!this.getCurrentUser().subscriptionExpired
    if (this.subscriptionExpired) {
      this.openChangeSubscriptionModal();
    }

    if (this.getCurrentUser().account_pay_type !== 'trial') {
      service.getInvoiceList().then((response) => {
        if (response) {
          this.invoiceList = response;
          if (this.invoiceList.length) {
            this.invoiceListLength = true;
          }
        }
      });
    }

    this.$api.get_country_list().then((list) => {
      this.countries = list;
    });

    (new UserService()).getCurrentUserStats().then((stats) => {
      this.clientCount = stats.clientCount;
      this.propertyCount = stats.propertyCount;
    });

    this.checkUser();
    this.getPlanDetail();
    this.getContactDetail();

    if (this.$route.params.open_plan_subscription) {
      this.openChangeSubscriptionModal();
    }

    if (this.$route.query.billingSuccess) {
      this.showBillingThanksModal = true;
    }
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    createOptionSelected(e) {
      this.laufzeit = e
    },
    checkUser() {
      if (this.getCurrentUser().account_pay_type === 'trial') {
        this.$router.push('/setting/BillingTrial')
      }
    },
    copyText(text) {
      const elem = document.createElement('textarea');
      elem.value = text;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.$toast.success(this.$t('settings.system.copy'))
    },
    changePaymentMethod() {
      this.isModalVisible = true
      this.showForm = "payment_type"
      this.active = 'payment_type_class';
    },
    updatePaymentMethod() {
      if (this.payment_method.debit_charge == "direct_debit") {
        if (!this.payment_method.account_owner) {
          this.account_ownererror = true
        } else {
          this.account_ownererror = false
        }
        if (!this.payment_method.bic) {
          this.bicerror = true
        } else {
          this.bicerror = false
        }

        if (this.payment_method.iban) {
          const billing = new BillingService();
          billing.checkIban(this.payment_method.iban, this.payment_method.bic).then((response) => {
            if (response.data.success) {
              this.iban_empty_error = false
              this.ibanerror = false
              if (!(this.account_ownererror) && !(this.bicerror)) {
                this.savePaymentMethod()
              }
            } else {
              if (response.data.error_key) {
                if (response.data.error_key == 'iban_error') {
                  this.$toast.error(this.$t('billing_subscribe.errors.ibanerror'));
                } else if(response.data.error_key == 'bic_error') {
                  this.$toast.error(this.$t('billing_subscribe.errors.bicerror'));
                } else if (response.data.error_key == 'iban_bic_mismatch') {
                  this.$toast.error(this.$t('billing_subscribe.errors.ibanbicmismatcherror'));
                }
              }
              this.ibanerror = true
              this.iban_empty_error = false
              return false;
            }
          })
        } else {
          this.iban_empty_error = true
          this.ibanerror = false
        }

      } else {
        this.payment_method.account_owner = ''
        this.payment_method.iban = ''
        this.payment_method.bic = ''
        this.savePaymentMethod()
      }
    },
    savePaymentMethod() {
      this.payment_method.plan_uid = this.getCurrentUser().plan_uid
      const service = new BillingService()
      service.updatePaymentMethod(this.payment_method, true).then((response) => {
        if (response.data.success) {
          this.getPlanDetail()
          this.isModalVisible = false
        }
      })
    },
    billPaymentDatail(invoiceDetail) {
      this.isModalPayVisible = true
      this.iban_number = invoiceDetail.iban_number;
      this.bic_code = invoiceDetail.bic_code;
      this.amount = invoiceDetail.net_price + ' €';
      this.OnlyAmount = invoiceDetail.net_price;
      this.invoice_number = 'RgNr. ' + invoiceDetail.invoice_number;
      this.pay_info = "fino taxtech GmbH";
    },
    async getPlanDetail() {
      if (this.getCurrentUser().plan_uid && parseInt(this.getCurrentUser().plan_uid) > 0) {
        const service = new BillingService()
        return service.get().then((response) => {
          if (response.data.success) {
            this.planDetail = response.data.data;

            if (this.planDetail.after_end_term != null) {
              this.laufzeit = this.planDetail.after_end_term;
            }

            this.after_end_plan_uid = this.planDetail.after_plan_uid;
            this.payment_method.debit_charge = this.planDetail.payment_mode;
            this.payment_method.iban = this.planDetail.iban_number;
            this.payment_method.bic = this.planDetail.bic_code;
            this.payment_method.account_owner = this.planDetail.bank_account_owner;
            this.totalBranch = this.planDetail.totalBranch;
          }
        })
      }
    },
    getContactDetail() {
      const service = new BillingService()
      service.getContactDetail().then((response) => {
        if (response.success) {
          response = response.response;

          this.form.company = response.billing_company;
          this.form.email = response.billing_email;
          this.form.salutation = response.billing_salutation;
          this.form.first_name = response.billing_first_name;
          this.form.last_name = response.billing_last_name;
          this.form.street = response.billing_street;
          this.form.house_number = response.billing_house_number;
          this.form.zip = response.billing_zip;
          this.form.city = response.billing_city;
          this.form.country_code = response.billing_country_code;
          this.form.country = response.billing_country_uid;
          this.country_uid = response.billing_country_uid;
          this.country_name = response.billing_country_name;

          if (typeof response.vat_registration_id === 'undefined') {
            if (typeof response.tax_number === 'undefined') {
              this.form.vat_registration_id = '';
            } else {
              this.form.vat_registration_id = response.tax_number;
            }
          } else {
            this.form.vat_registration_id = response.vat_registration_id;
          }

          this.form.account_owner = response.bank_account_owner;
          this.form.iban = response.iban;
          this.form.bic = response.bic;

          this.form.billing_xrechnung_email_address = response.billing_xrechnung_email_address;
          this.form.billing_xrechnung_buyer_reference = response.billing_xrechnung_buyer_reference;
          this.form.billing_xrechnung_vendor_number = response.billing_xrechnung_vendor_number;
        }
      });

      if (this.form.company == '') {
        this.form.company = this.form.first_name + ' ' + this.form.last_name;
      }
    },
    downloadInvoice(invoice_url) {
      window.open(invoice_url);
    },
    onFormChange() {
      this.form.validate()
    },
    updateContact() {
      if (this.form.country != 50) {
        let Validator = this.form.validator();
        Validator.addRule('vat_registration_id', 'required');
      }

      this.form.validate()
      if (this.form.validate().errors().any()) {
        return false;
      }

      if (typeof this.form.data.vat_registration_id !== 'undefined') {
        this.form.data.vat_registration_id = this.form.data.vat_registration_id.replace(/\s/g, '');
      }

      const billing = new BillingService();
      billing.updateContact(this.form.data).then((response) => {
        if (response.data.success) {
          this.handleModalClose();
        }
      });
    },
    submit(status) {
      if (status) {
        if (status === 'termination') {
          this.isModalVisible = false;
          this.showPlanCancellationModal = true;

          return;
        }

        if (status === 'another_package') {
          this.openChangeSubscriptionModal(true);
          return;
        }

        const service = new BillingService()
        service.after_end_term({
          'status': status,
          'after_plan_uid': '',
          'plan_uid': this.getCurrentUser().plan_uid
        }, true).then((response) => {
          if (response.data.success) {
            this.getPlanDetail();
            this.isModalVisible = false;
          }
        });
      }
    },
    openpopup() {
      this.isModalVisible = true
      this.showForm = false
      this.active = 'bill_address_class';
    },
    generatePdf() {
      const service = new BillingService()
      service.generatePdf({
        "plan_uid": this.getCurrentUser().plan_uid,
        "iban": this.payment_method.iban,
        "bic": this.payment_method.bic
      })
    },
    openChangeSubscriptionModal(forAfterEndTerm = false) {
      this.changeSubscriptionAfterEndTerm = forAfterEndTerm;
      this.showChangeSubscriptionModal = true;
    },
    closeChangeSubscriptionModal() {
      this.showChangeSubscriptionModal = false;
      this.changeSubscriptionAfterEndTerm = false;
      this.isModalVisible = false; // After End Term Modal
      this.getPlanDetail();
    },
    term_plan() {
      this.active = 'after_end_term_class';
      this.isModalVisible = true
      this.showForm = 'plan'
    },
    handleModalClose() {
      this.isModalVisible = false;
      this.getContactDetail();
      this.$api.get_user(false);
      if(this.getCurrentUser().subscriptionExpired && this.getCurrentUser().afterEndTerm && this.getCurrentUser().afterEndTerm === 'termination') {
        this.logout();
      }
    },
    handleModalPayClose() {
      this.isModalPayVisible = false
    },
    handlePlanCancellationModalClose() {
      this.showPlanCancellationModal = false;
      this.getPlanDetail();
    },
    downloadCSV(exportColumn, invoiceId) {
      const declarationService = new DeclarationService()
      declarationService.downloadCSV(
        exportColumn,
        {"invoice_uid": invoiceId}
      )
    },
    onChangeCountry () {
      if (typeof this.countries.find(item => item.code == this.form.country) !== 'undefined') {
        this.form.country_code = this.countries.find(item => item.code == this.form.country).country_code;
      }
    },
    connectGmi() {
      if(this.isGetMyInvoicesConnected) {
        this.$modal.show({
          headerText: this.$t('connect_gmi.deactivate.title'),
          title: this.$t('connect_gmi.deactivate.info'),
          showClose: true,
          canEscape: true,
          buttons: {
            ok: this.$t('connect_gmi.deactivate.btn_deactivate'),
            delete: false
          },
          onConfirm: () => {
            this.$api.trigger('connect_getmyinvoices/deactivate', null, true).then((response) => {
              if(response.data.success) {
                this.$api.get_user(true); // Update current user data in store
              } else {
                this.$api.showToast(response.data.message, 'error');
              }
            });
          }
        });
      } else {
        this.$router.push('/setting/ConnectGetMyInvoices');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.setting-header {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

table#plan_table td, table#plan_table th {
  border: 1px solid rgba(0, 0, 0, .1);
  margin: 20px auto;
}

table#plan_table tr th:first-child {
  border-color: #fff;
  border-width: 1px 0px 0px 1px;
}

table#plan_table tr:last-child td:first-child {
  border-color: #fff;
  border-width: 0px 0px 0px 0px;
}

.mod_label {
  position: absolute;
  top: 10px;

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

table#plan_table tr td:first-child {
  font-size: 15px;
  font-weight: 500;
}

table#plan_table tr td:not(:first-child) {
  text-align: center;
  font-size: 15px;
}

.box {
  height: 50px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

span.pln_tile {
  display: block;
  text-align: center;
  font-size: 18px;
  color: theme('colors.muted_black');
}

.inpt_rad {
  display: flex;
  align-items: center;
}

.radio_col label {
  min-width: 200px;
  display: flex;
  align-items: center;
}

.col_12.pd_0 {
  max-width: 200px;
}

.modal_norm .modal {
  position: relative;
}

.modal_wide .modal {
  max-width: 80% !important;
  width: 100% !important;
  position: relative;
}

.modal-backdrop.flex.justify-center.items-center.modal_wide .modal {
  max-width: 80% !important;
  width: 100% !important;
  position: relative;
}

.min_30 {
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  text-align: right;
  padding-right: 20px;
}

.tab_title {
  margin: 0 0 20px 0 !important;
  width: 100% !important;
  display: block;
  clear: both;
}

ul.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 10px 0 15px 0;
}

ul.breadcrumb > li:not(:last-child):before {
  content: ">";
  position: absolute;
  right: -15px;
}

ul.breadcrumb > li {
  margin-right: 20px;
  position: relative;
}



.form_grp label {
  display: block;
  line-height: 30px;
  margin-bottom: 4px;
  color: #000100;
}

.radio_hold3 {
  margin-top: 30px;
}

.radio_hold3 label.vnt-radio {
  display: flex;
}

.table_15 label, .table_15 p, .table_15 td, .table_15 th {
  font-size: 15px;
}

.btn_right {
  margin-top: 50px;
}

span.sc_icr {
  display: flex;
  justify-content: center;
}

span.sc_icr img {
  width: 100px;
  height: 100px;
}

header.modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 20px !important;
}

.sucss_msg {
  margin-top: 50px;
}

.sucss_msg label {
  font-size: 20px;
  font-weight: 600 !important;
  color: theme('colors.muted_black');
  margin: 20px auto;
  display: block;
}

.sucss_msg p {
  font-size: 15px;
  display: block;
  margin: 0 0 30px 0;
}

.sucss_msg a {
  background: $primary;
  color: #fff !important;
  font-size: 15px;
  padding: 8px;
  max-width: 230px;
  width: 100% !important;
  display: inline-block;
}

.btn_right {
  justify-content: flex-end !important;
  display: flex;
}

.btn_right > button {
  margin: 0 0 0 20px !important;
}

header.modal-header {
  justify-content: flex-end !important;
}

.edit {
  width: 23px;
  height: 23px;
  box-sizing: border-box;
  margin-right: 12px;
}

.check_cond span {
  font-size: 15px;
}

.check_cond span a {
  color: rgba(34, 157, 86, 1);
  font-weight: 600;
  text-decoration: underline;
}

.msg_9 {
  max-width: calc(100% - 400px);
  align-items: center;
  text-align: left;
  font-size: 14px;
}

.top_dec {
  display: flex;
  justify-content: space-between;
}


.col_3 {
  max-width: 400px;
  width: 100%;
}

.devider {
  display: block;
  font-size: 15px;
  padding: 0 0 20px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
}

.form_detail {
  padding: 20px 0;
}

.radio_inline {
  display: inline-block;
}

span.vnt-radio__icon {
  display: inline-block;
}

.radio_hold {
  display: flex;
  justify-content: flex-start;
}

.form_grp {
  display: flex;
  flex-wrap: wrap;
  //padding: 0 10px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  flex: 100%;
}

.form_grp .col_12, .form_grp .col_4 {
  position: relative;
  padding-bottom: 20px !important;
  text-align: left;
}

.form_grp .input-box {
  height: 30px !important;
  border: 2px solid #7a7a7a;
}

#app .vnt-select__title {
  display: block;
  line-height: 22px !important;
  margin-bottom: 4px;
  color: #000100;
}

.vnt-select__chosen {
  border: 2px solid #7a7a7a;
  height: 32px;
}

.inf {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  padding: 10px;
  margin: 20px 10px 0 0;
  font-size: 12px;
}

.inf > div {
  width: 60px;
}

span.err {
  bottom: 0;
  font-size: 12px;
  text-align: left;
  width: 100%;
  display: block;
  color: red;
  font-family: "Segoe UI", sans-serif;
}

label.rad_label {
  font-size: 15px;
  margin-bottom: 15px;
}

.col_4 {
  max-width: 33.33%;
  width: 100%;
  padding: 0 15px;
}

.col_12.form_btnd > button:first-child {
  background: rgba(34, 157, 86, 1);
  color: #fff;
  font-size: 15px;
  display: inline-block;
  padding: 10px;
  margin: 0 20px;
  width: 240px;
}

.col_12.form_btnd > button:nth-child(2) {
  background: rgba(204, 204, 204, 1);
  color: theme('colors.muted_black');
  font-size: 15px;
  width: 240px;
  display: inline-block;
  padding: 10px;
  margin: 0 20px;
}

.col_12 {
  width: 100%;
  padding: 0 15px;
}

.form_grp input, .form_grp select {
  font-size: 15px;
}

.input-box {
  height: 30px !important;
  color: theme('colors.muted_black');
}

.box {
  height: 50px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.vnt-select {
  margin: 0;
}

.box1 {
  height: 55px;
  padding: 2px 2px 2px 2px;
  background-color: white;
  box-sizing: border-box;
  border-left-width: 1px;
  border-left-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
}

.date2 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;

  color: theme('colors.muted_black');
  text-align: right;
  line-height: 25px;
  font-size: 15px;
}

.vnt-select__chosen {
  height: 32px !important;
}

.tbox {
  border-left-width: 1px;
  border-left-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
}

.p1 {
  height: 42px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 15px;
}

.down-arrow {
  width: 15px;
  height: 20px;
  box-sizing: border-box;
}


.p2 {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 30px;
  font-size: 15px;
}

.p3 {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 30px;
  font-size: 15px;
}

.collapse .collapse-header {
  padding: 20px 20px 20px 40px;
  background: #f7f7f7;
  border-radius: 3px;
  /* position: relative; */
}

.map {
  border: 1px solid #797979;
  background-color: #ffffff;
  box-sizing: border-box;
}

th {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
}

.floating {
  float: left;
}

.text-2 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.text-to {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;

  font-size: 15px;


}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}


.line {
  color: #dfdfdf;

}

.rectangle {
  height: auto;
  padding-bottom: 15px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.id {

  height: 26px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: right;
  line-height: normal;
  font-size: 15px;
  //margin-right: 15px;
}


input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {
  color: theme('colors.muted_black');
  font-size: 15px;
  padding: 0px 0px 0px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-align: left;
  line-height: normal;
}

.dialog-header {
  position: absolute;
  top: 10px;

  padding: 0 0px;


  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;

  font-size: 20px;
}

.button {
  background-color: rgba(204, 204, 204, 1);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  font-size: 15px;
}

.text-color {
  color: $primary
}

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;

  width: 69px;
  height: 33px;
  border-radius: 20px;

  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  // box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}


/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.link {
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: $primary;
  font-size: 15px;
}

.disabled-box {

  height: 32px;
  padding: 2px 2px 2px 2px;
  background-color: #f4f1f3;
  box-sizing: border-box;

  .disabled-text {
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    color: #aeaeae;
    text-align: left;
    line-height: 20px;
    font-size: 15px;
    padding-left: 5px;
  }

  .img {
    width: 16px;
    height: 18px;
    margin-right: 5px;
  }

}

.text-right-id {
  font-size: 15px;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: right;
  line-height: normal;
}

.duration {
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.discount {
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
}

table#plan_table td, table#plan_table th {
  border: 1px solid #DFDFDF;
  margin: 20px auto;
}

@media only screen and (max-width: 1370px) {
  .table_def td, .table_def th, .table_def div {
    flex-wrap: wrap;
    white-space: normal;
    //border-bottom: thin solid #e6e3e3;
  }

}

table.table_def td, table.table_def th {
  border-bottom: thin solid #e6e3e3;
}

.billing-set {
  display: flex !important;
}

.extra_text {
  font-size: 15px;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: center;
  line-height: normal;
}

.background-color-light-blue {
  background-color: #effff7 !important;
}

.background-color-light-orange {
  background-color: #fff1c0 !important;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.font-size-13 {
  font-size: 13px !important;
}

.min-height {
  min-height: 36px;
}
.plan-dialog {
  height: calc(100vh - 100px);
}
.connected-gmi ::v-deep {
  .button, .button:hover {
    background-color: #245448;
  }
}

.error-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  float: left;
}
</style>
