<template>
  <div class="h-full bg-white pl-7 w-full ">
    <Breadchrumbs class="mt-2" :breadcrumbs="breadcrumbs"/>
    <p class="header-component-edit mt-4"> {{ $t('settings.branches.edit_branch') }}</p>
    <hr class="hr-line-user  mr-7 mb-1 mt-8 "/>
    <div class="w-full h-full mt-4  pb-8">
      <AppTabs :categories="categories" :splitSaveButton="true" :save-handler="submit" @buttonClick="tabsButtonClicked">
        <!-- tab1 -->
        <template #tab-pane-contact>
          <div class="flex justify-around space-x-8">
            <InputText class="w-full"
                       :label="$t('settings.branches.attrs.name')"
                       :isError="isNameError"
                       :errorMessage="nameError" v-model="branchData.name"
                       :isRequired="true" :placeholder="$t('settings.branches.attrs.name')"/>
            <InputText class="w-full "
                       :label="$t('settings.branches.attrs.street_and_house_number')"
                       :isError="isStreetError"
                       :errorMessage="streetError" v-model="branchData.street"
                       :isRequired="false" :placeholder="$t('settings.branches.attrs.street_and_house_number')"/>
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputText class="w-full"
                       :label="$t('settings.branches.attrs.zip')"
                       :isError="isZipError"
                       :errorMessage="zipError"
                       v-model="branchData.zip"
                       @blur="searchZip"
                       :isRequired="false"
                       :isZip="true"
                       :placeholder="$t('settings.branches.attrs.zip')"
                      />
            <InputSelect
                class="w-full "
                :label="$t('settings.branches.attrs.city')"
                :options="cityList"
                v-model="branchData.city"
                :selected-value="branchData.city"
                :loading="zipSearch"
                :filterable="true"
                :auto-complete="true"
                :placeholder="$t('settings.branches.attrs.city')"
              />
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputText class="w-full"
                       :label="$t('settings.branches.attrs.phone')"
                       :isError="isPhoneError"
                       :errorMessage="phoneError"
                       v-model="branchData.phone"
                       :isRequired="false"
                       :maxLength="16"
                       :placeholder="$t('settings.branches.attrs.phone')"/>
            <InputText class="w-full"
                       :label="$t('settings.branches.attrs.email')"
                       v-model="branchData.email"
                       :placeholder="$t('settings.branches.attrs.email')"/>
          </div>
          <div class="flex justify-around space-x-8 mt-4">
            <InputSelect
              class="w-full"
              :label="$t('settings.powers_of_attorney.default_power_of_attorney')"
              :options="powersOfAttorneyList"
              v-model="branchData.power_of_attorney"
              :selected-value="branchData.power_of_attorney"
              :placeholder="$t('general.please_select')"
            />
            <div class="w-full  invisible"/>
          </div>
        </template>

        <!-- tab 2 -->
        <template #tab-pane-user>
          <div class="box">
            <div class=" flex justify-center items-center">
              <img class="image ml-2" src="@/assets/info_gray.svg" alt=""/>
              <label class="text-left ml-2">{{ $t('settings.branches.all_branch_access_info') }}</label>
            </div>
          </div>
          <DualListSelect v-if="!userLoading" :list="users" v-model="branchData.selected_users"
                          :columns="columns"
                          :leftHeader="$t('settings.branches.attrs.all_users')"
                          :rightHeader="$t('settings.branches.attrs.selected_users')"
                          :showLeftHelpIcon="true"
                          :leftTooltip="$t('settings.branches.dualListTooltip.left')"
                          :showRightHelpIcon="true"
                          :rightTooltip="$t('settings.branches.dualListTooltip.right')"
          />
        </template>
        <template #tab-pane-protocol>
          <VersionGrid :parent-id="parseInt(branchData.prim_uid)" parent-type="branches"/>
        </template>
      </AppTabs>
    </div>
  </div>
</template>

<script>
import AppTabs from "@/components/AppTabs";
import {UserService} from "../../services/user.service";
import {BranchService} from "../../services/branch.service";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";
import {required} from "vuelidate/lib/validators";
import {mapMutations} from "vuex";
import VersionGrid from "../VersionGrid";

export default {
  name: "EditClient",
  components: {
    AppTabs,
    VersionGrid
  },
  props: {
    set: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      branchService: new BranchService(),
      currentCategory: 'contact',
      branchData: {
        name: this.$store.state.branch.name,
        street: this.$store.state.branch.street,
        zip: this.$store.state.branch.zip,
        city: this.$store.state.branch.city,
        phone: this.$store.state.branch.phone,
        email: this.$store.state.branch.email,
        selected_users: []
      },
      isNameError: false,
      nameError: '',
      isStreetError: false,
      streetError: '',
      isZipError: false,
      zipError: '',
      isCityError: false,
      cityError: '',
      isPhoneError: false,
      phoneError: '',
      isConsultantNumbersError: false,
      consultantNumbersError: '',
      userLoading: true,
      users: [],
      tabsFields: [
        {
          name: this.$t('settings.branches.tabs.tab1'),
          slug: 'contact',
          fields:[
            {
              name:this.$t('settings.branches.attrs.name'),
              slug:'name'
            },
          ]
        }, {
          name: this.$t('settings.branches.tabs.tab3'),
          slug: 'user',
          fields:[]
        }, {
          name: this.$t('settings.branches.tabs.tab4'),
          slug: 'protocol',
          fields:[]
        },
      ],
      categories: [
        {
          name: this.$t('settings.branches.tabs.tab1'),
          slug: 'contact'
        }, {
          name: this.$t('settings.branches.tabs.tab3'),
          slug: 'user'
        }, {
          name: this.$t('settings.branches.tabs.tab4'),
          slug: 'protocol'
        },
      ],
      columns: [
        {
          field: 'name',
          label: this.$t('settings.user_management.attrs.name'),
          search: true
        },
        {
          field: 'role',
          label: this.$t('settings.user_management.attrs.role')
        }
      ],
      breadcrumbs: [
        {
          title: this.$t('settings.branches.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.branches.menu.branches'),
          link: this.$isBusiness ? '/setting/departments' : '/setting/branches'
        },
        {
          title: this.$t('settings.branches.edit_branch'),
          link: ''
        }
      ],
      powersOfAttorneyList: [],
      cityList: [],
      zipSearch: false,
    }
  },
  validations: {
    branchData: {
      name: {
        required
      }
    }
  },
  async beforeCreate() {
    let user = new UserService();
    this.users = await user.listAll();
    this.userLoading = false;
  },
  mounted() {
    this.branchService.get(this.$route.query.uid).then(branchData => {
      if (branchData.prim_uid) {
        if (branchData.selected_users.length > 0) {
          this.users = this.users.filter(a => !branchData.selected_users.map(b => b.prim_uid).includes(a.id))
          branchData.selected_users = branchData.selected_users.filter((o) => {
            o.disableDelete = parseInt(o.all_branch_access) === 1;
            return o;
          });
        }
        this.branchData = branchData;
        this.saveAccessLog({
          "recordType": "branch",
          "recordUid": branchData.prim_uid
        });
      } else {
        this.$router.go(-1);
      }
    });

    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      this.powersOfAttorneyList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id
        }
      });
      this.powersOfAttorneyList.unshift({
          "name": this.$t('settings.powers_of_attorney.default_power_of_attorney_no'),
          "code": 0
        });
    });
  },
  methods: {
    ...mapMutations("branch", ["setBranchData"]),
    ...mapMutations("user", ["saveAccessLog"]),
    searchZip() {
      this.zipSearch = true;
      if ((typeof this.branchData.city === 'undefined' || this.branchData.city === '') && this.branchData.zip !== '') {
        this.cityList = [];
        this.$api.lookup_city(this.branchData.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.branchData.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    showErrorNotification(field){
      let outerField= this.tabsFields.find(x=>x.fields.find((str) => str.slug === field))
      let field_index=this.tabsFields.findIndex(x=>x.fields.find((str) => str.slug === field))
      let field_name=this.tabsFields[field_index].fields.find((str) => str.slug === field)
      let message= this.$t('general.check_fields').replace('%tab%', outerField.name).replace('%field%', field_name.name)
      this.$api.showToast(message,'error');
    },
    async submit(btn_key) {
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        this.isNameError = this.$v.branchData.name.$error
        this.nameError = this.$v.branchData.name.$error ? this.$t('settings.branches.errors.required_name') : ""
        this.isNameError ?this.showErrorNotification('name'):''
        return; // if any of the validations fail, we don't move on
      }

      if (await this.branchService.save(this.branchData)) {
        this.$api.showToast(this.$t('settings.branches.branch_updated_info'))
        if (btn_key === 'saveList') {
          this.$router.push(this.$isBusiness ? '/setting/departments' : '/setting/branches')
        } else if (btn_key === 'saveNew') {
          this.$router.push('/branch/add')
        }
      }
    },
    tabsButtonClicked(btn_key) {
      if (btn_key === 'cancel') {
        this.$router.go(-1);
      }
    },
    handleTabClick(args) {

      this.currentCategory = args

      let index = this.categories.findIndex(item => item.slug === args)
      this.categories = this.categories.map((x, i) => {
        return i === index ? {...x, isActive: true} : {...x, isActive: false}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
}

.label-white {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: white;
  text-align: left;
}

html, body {
  background-color: #fff;
  height: 100%;
}

.button-label {
  width: 200px;
  @include segoe-regular;

  &__link {
    color: $primary;
  }

  &__text-black {
    color: black;
  }
}

.checkbox {
  height: 37px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  border-radius: 2px;
  background-color: #ffffff;

  &.checked {
    color: black;
    text-decoration: line-through
  }

}

.chip-container {

  border: 2px solid #7a7a7a;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    display: flex;
    align-items: center;


    height: 22px;
    border-radius: 15px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: 'Arial Regular', 'Arial', sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;

    img {
      cursor: pointer;
      margin-left: 8px;
      font-size: 12px;
      font-style: none;

      width: 8px;
      height: 8px;
      box-sizing: border-box;
      font-family: 'Arial Regular', 'Arial', sans-serif;
      color: #333333;
      text-align: center;
      line-height: normal;
    }
  }

  .chip-logo {

    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {


    @include segoe-regular;
    color: #7b7c7c;
    font-size: 12px;

  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

//properties
th {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;

  //&:hover {
  //  //.icontable {
  //  //  visibility: visible;
  //  //}
  //
  //}

  //.icontable {
  //  visibility: collapse;
  //}
}

.wrapper-menu {
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.title {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 10px;
}

.mand {

  height: 32px;
  padding: 2px 2px 2px 2px;
  background-color: #f4f1f3;
  box-sizing: border-box;
  margin-bottom: 25px;

  &__text {

    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: #aeaeae;
    text-align: left;
    font-size: 15px;
    margin-left: 4px;
  }
}

.box {

  padding: 10px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

//.text {
//  background-color: rgba(255, 255, 255, 0);
//  box-sizing: border-box;
//  font-family:  'Segoe UI', sans-serif;
//  color: #333333;
//  text-align: left;
//  line-height: 20px;
//  font-size: 15px;
//  margin-bottom: 15px;
//}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.inventory {
  width: 16px;
  height: 20px;

}

.label-link {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: #229d56;
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.rect {

  height: auto;
  padding: 10px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-left: 5px;
}

.textbold {

  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
}

.password-text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.label-disabled {
  width: 367px;
  padding: 5px;
  background-color: #f4f1f3;
  box-sizing: border-box;
  color: #aeaeae;
  text-align: left;
}

.box {
  height: 64px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 18px;
}

.rectangle {
  width: 648px;

  padding: 2px 2px 2px 2px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 1px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: left;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-table {
  width: 22px;
  height: 22px;
  box-sizing: border-box;
}


.input {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  font-family: 'Segoe UI', sans-serif;
  color: #000000;
  text-align: left;
  background-color: transparent;
  box-sizing: border-box;

  margin-top: -15px;
  margin-left: 25px;
  font-size: 13px;
}

.icon-table-cross {
  width: 15px;
  height: 15px;
}
.box {
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
}

</style>
